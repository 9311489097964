import React, { useState } from 'react';
import { connect } from 'react-redux';
import { fetchImages } from 'store/actions/image';
import { createImage } from 'store/actions/image';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});

const CustomizedInputBase = (props) => {
  const [query, setQuery] = useState('');

  const handleSelectFiles = (e) => {
    if (e.target.files.length === 0) return false;
    const files = Array.from(e.target.files);
    for (let index = 0; index < files.length; index += 1) {
      const formData = new FormData();
      formData.append('uploaded_image', files[index]);
      props.createImage(formData);
    };
  };

  const classes = useStyles();

  const handleSearch = () => {
    props.fetchImages({ 'q[description_cont]': query });
  }

  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Pesquisar por imagem"
        onChange={(e) => setQuery(e.target.value)}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            ev.preventDefault();
            handleSearch();
          }
        }}
      />
      <IconButton
        className={classes.iconButton}
        onClick={() => handleSearch()}
      >
        <SearchIcon />
      </IconButton>
      <Divider className={classes.divider} />
      <IconButton
        variant="contained"
        component="label"
        color="primary"
        className={classes.iconButton}
      >
        <AddIcon />
        <input
          type="file"
          multiple
          onChange={handleSelectFiles}
          style={{ display: "none" }}
        />
      </IconButton>
    </Paper>
  );
}

const actions = {
  fetchImages,
  createImage,
};

export default connect(null, actions)(CustomizedInputBase);