import React, { useState } from 'react';
import { connect } from 'react-redux';
import { fetchImages } from 'store/actions/image';
import {
  Button,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import TextImageDialog from './imageInTextDialog';
import GalleryDialog from './GalleryDialog';
import EmbedDialog from './EmbedDialog';

const Image = (props) => {
  const [openTextImageDialog, setOpenTextImageDialog] = useState(false);
  const [openGalleryDialog, setOpenGalleryDialog] = useState(false);
  const [openEmbedDialog, setOpenEmbedDialog] = useState(false);

  const handleOpenTextImageDialog = () => {
    setOpenTextImageDialog(true);
    props.fetchImages();
  };

  const handleOpenGalleryDialog = () => {
    setOpenGalleryDialog(true);
    props.fetchImages();
  };

  const handleOpenEmbedDialog = () => {
    setOpenEmbedDialog(true);
  };

  return (
    <Card>
      <CardContent style={{ paddingBottom: 16 }}>
        <Typography style={{ marginBottom: "0.5em" }}>
          Ferramentas
        </Typography>
        <Button
          variant="outlined"
          fullWidth
          onClick={handleOpenTextImageDialog}
          style={{ marginBottom: "0.5em" }}
        >
          Inserir imagem no texto
        </Button>
        <Button
          variant="outlined"
          fullWidth
          onClick={handleOpenGalleryDialog}
          style={{ marginBottom: "0.5em" }}
        >
          Inserir imagens na galeria
        </Button>
        <Button
          variant="outlined"
          fullWidth
          onClick={handleOpenEmbedDialog}
        >
          Inserir embed no texto
        </Button>
      </CardContent>
      {openTextImageDialog && (
        <TextImageDialog
          open={openTextImageDialog}
          handleClose={setOpenTextImageDialog}
          editor={props.editor}
        />
      )}
      {openGalleryDialog && (
        <GalleryDialog
          open={openGalleryDialog}
          handleClose={setOpenGalleryDialog}
          setFieldValue={props.setFieldValue}
        />
      )}
      {openEmbedDialog && (
        <EmbedDialog
          open={openEmbedDialog}
          handleClose={setOpenEmbedDialog}
          value={props.values.embed}
          setFieldValue={props.setFieldValue}
        />
      )}
    </Card>
  );
}

export default connect(null, { fetchImages })(Image);
