export const FETCH_IMAGES = 'FETCH_IMAGES';
export const FETCH_IMAGES_FULFILLED = 'FETCH_IMAGES_FULFILLED';
export const FETCH_IMAGES_REJECTED = 'FETCH_IMAGES_REJECTED';

export const FETCH_IMAGE = 'FETCH_IMAGE';
export const FETCH_IMAGE_FULFILLED = 'FETCH_IMAGE_FULFILLED';
export const FETCH_IMAGE_REJECTED = 'FETCH_IMAGE_REJECTED';

export const CREATE_IMAGE = 'CREATE_IMAGE';
export const CREATE_IMAGE_FULFILLED = 'CREATE_IMAGE_FULFILLED';
export const CREATE_IMAGE_REJECTED = 'CREATE_IMAGE_REJECTED';

export const CREATE_IMAGES = 'CREATE_IMAGES';
export const CREATE_IMAGES_FULFILLED = 'CREATE_IMAGES_FULFILLED';
export const CREATE_IMAGES_REJECTED = 'CREATE_IMAGES_REJECTED';

export const UPDATE_IMAGE = 'UPDATE_IMAGE';
export const UPDATE_IMAGE_FULFILLED = 'UPDATE_IMAGE_FULFILLED';
export const UPDATE_IMAGE_REJECTED = 'UPDATE_IMAGE_REJECTED';

export const REMOVE_IMAGE = 'REMOVE_IMAGE';
export const REMOVE_IMAGE_FULFILLED = 'REMOVE_IMAGE_FULFILLED';
export const REMOVE_IMAGE_REJECTED = 'REMOVE_IMAGE_REJECTED';

export const OPEN_IMAGE_DIALOG = 'OPEN_IMAGE_DIALOG';
export const OPEN_IMAGE_DIALOG_FULFILLED = 'OPEN_IMAGE_DIALOG_FULFILLED';
export const OPEN_IMAGE_DIALOG_REJECTED = 'OPEN_IMAGE_DIALOG_REJECTED';

export const CLOSE_IMAGE_DIALOG = 'CLOSE_IMAGE_DIALOG';