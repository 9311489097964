import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  Grid,
  TextField,
} from '@material-ui/core';
import {
  DialogTitle,
  DialogContent,
  DialogActions
} from 'utils/CustomDialog';

const EmbedDialog = (props) => (
  <Dialog
    fullWidth
    maxWidth="xs"
    onClose={() => props.handleClose(false)}
    open={props.open}
  >
    <DialogTitle onClose={() => props.handleClose()}>
      Inserir embed
    </DialogTitle>
    <DialogContent dividers>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <TextField
            multiline
            value={props.value}
            variant="outlined"
            fullWidth
            onChange={e => props.setFieldValue('embed', e.target.value)}
          />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => props.handleClose(false)}
        color="primary"
        variant="contained"
      >
        Salvar
      </Button>
    </DialogActions>
  </Dialog>
);

const mapStateToProps = state => ({
  imageIds: state.post.form.imageIds,
  images: state.image.collection,
});

export default connect(mapStateToProps)(EmbedDialog);
