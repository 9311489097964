import { push } from 'connected-react-router';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as api from 'api';
import * as definitions from 'store/definitions/blog';

export function* fetchPosts(action) {
  try {
    const filter = action.payload;
    const payload = yield call(api.fetch, 'blogs', filter);
    yield put({ type: definitions.FETCH_BLOGS_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_BLOGS_REJECTED, error });
  }
}

export function* fetchPost(action) {
  try {
    const id = action.payload;
    const payload = yield call(api.find, 'blogs', id);
    yield put({ type: definitions.FETCH_BLOG_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_BLOG_REJECTED, error });
  }
}

export function* createPost(action) {
  try {
    const { data, image } = action.payload;
    const payload = yield call(api.create, 'blogs', { blog: data });
    yield call(api.upload, 'blogs', payload.id, image);
    yield put({ type: definitions.CREATE_BLOG_FULFILLED, payload });
    yield put(push('/blogs'));
  } catch (error) {
    yield put({ type: definitions.CREATE_BLOG_REJECTED, error });
  }
}

export function* updatePost(action) {
  try {
    const { data, image } = action.payload;
    const { id } = data;
    const payload = yield call(api.update, 'blogs', id, { blog: data });
    if (image) yield call(api.upload, 'blogs', id, image);
    // yield call(api.clearSiteCache);
    yield put({ type: definitions.UPDATE_BLOG_FULFILLED, payload });
    yield put(push('/blogs'));
  } catch (error) {
    yield put({ type: definitions.UPDATE_BLOG_REJECTED, error });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.FETCH_BLOGS, fetchPosts),
    takeLatest(definitions.CREATE_BLOG, createPost),
    takeLatest(definitions.FETCH_BLOG, fetchPost),
    takeLatest(definitions.UPDATE_BLOG, updatePost),
  ]);
}