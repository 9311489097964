export const FETCH_CONFIG = 'FETCH_CONFIG';
export const FETCH_CONFIG_FULFILLED = 'FETCH_CONFIG_FULFILLED';
export const FETCH_CONFIG_REJECTED = 'FETCH_CONFIG_REJECTED';

export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const UPDATE_CONFIG_FULFILLED = 'UPDATE_CONFIG_FULFILLED';
export const UPDATE_CONFIG_REJECTED = 'UPDATE_CONFIG_REJECTED';

export const OPEN_CONFIG_DIALOG = 'OPEN_CONFIG_DIALOG';
export const OPEN_CONFIG_DIALOG_FULFILLED = 'OPEN_CONFIG_DIALOG_FULFILLED';
export const OPEN_CONFIG_DIALOG_REJECTED = 'OPEN_CONFIG_DIALOG_REJECTED';

export const CLOSE_CONFIG_DIALOG = 'CLOSE_CONFIG_DIALOG';
export const CLOSE_CONFIG_DIALOG_FULFILLED = 'CLOSE_CONFIG_DIALOG_FULFILLED';
export const CLOSE_CONFIG_DIALOG_REJECTED = 'CLOSE_CONFIG_DIALOG_REJECTED';