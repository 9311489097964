import * as definitions from "store/definitions/post";
import { LOCATION_CHANGE } from "connected-react-router";

const formState = {
  id: null,
  hat: "",
  title: "",
  summary: "",
  mustache: "",
  body: "",
  categoryId: "",
  position: "",
  credit: "",
  embed: "",
  tags: "",
  url: "",
  link: "",
  cacheControl: "",
  scheduledTo: new Date(),
  allowComments: true,
  allowSocialNetwork: true,
  isActive: true,
  featured: false,
  sponsored: false,
  imageIds: [],
};

const initialState = {
  collection: [],
  total: 0,
  currentPage: 1,
  search: "",
  form: formState,
  loading: true,
};

function Post(state = initialState, action) {
  switch (action.type) {
    case definitions.FETCH_POSTS:
    case definitions.FETCH_POST:
      return {
        ...state,
        loading: true,
      };
    case definitions.FETCH_POSTS_FULFILLED: {
      return {
        ...state,
        collection: action.payload.data,
        total: action.payload.total,
        currentPage: action.payload.currentPage,
        search: action.payload.search,
        loading: false,
      };
    }
    case definitions.FETCH_POST_FULFILLED: {
      return {
        ...state,
        form: action.payload,
        loading: false,
      };
    }
    case LOCATION_CHANGE:
      return {
        ...state,
        form: {
          ...formState,
          scheduledTo: new Date(),
        },
      };
    default:
      return state;
  }
}

export default Post;
