import { push } from "connected-react-router";
import { all, call, put, takeLatest } from "redux-saga/effects";
import * as api from "api";
import * as definitions from "store/definitions/user";

export function* fetchUsers(action) {
  try {
    const filter = action.payload;
    const payload = yield call(api.fetch, "users", filter);
    yield put({ type: definitions.FETCH_USERS_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_USERS_REJECTED, error });
  }
}

export function* fetchUser(action) {
  try {
    const id = action.payload;
    const payload = yield call(api.find, "users", id);
    yield put({ type: definitions.FETCH_USER_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_USER_REJECTED, error });
  }
}

export function* createUser(action) {
  try {
    const payload = yield call(api.create, "users", { user: action.payload });
    yield put({ type: definitions.CREATE_USER_FULFILLED, payload });
    yield put(push("/users"));
  } catch (error) {
    yield put({ type: definitions.CREATE_USER_REJECTED, error });
  }
}

export function* updateUser(action) {
  try {
    const { id } = action.payload;
    const payload = yield call(api.update, "users", id, { user: action.payload });
    yield put({ type: definitions.UPDATE_USER_FULFILLED, payload });
    yield put(push("/users"));
    window.location.reload();
  } catch (error) {
    yield put({ type: definitions.UPDATE_USER_REJECTED, error });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.FETCH_USERS, fetchUsers),
    takeLatest(definitions.CREATE_USER, createUser),
    takeLatest(definitions.FETCH_USER, fetchUser),
    takeLatest(definitions.UPDATE_USER, updateUser),
  ]);
}
