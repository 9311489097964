import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
// import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootSagas from './sagas';
import createRootReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();

// const loggerMiddleware = createLogger();

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        sagaMiddleware,
        // loggerMiddleware,
      ),
    ),
  );

  sagaMiddleware.run(rootSagas);

  return store;
};
