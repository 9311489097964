import React, { useState } from "react";
import Cropper from "react-easy-crop";
import { connect } from "react-redux";
import {
  updateImage,
  closeImageDialog,
  fetchImages,
} from "store/actions/image";
import { Formik, Field, Form } from "formik";
import { TextField, Switch } from "formik-material-ui";
import { Button, Dialog, Grid } from "@material-ui/core";
import { DialogTitle, DialogContent } from "utils/CustomDialog";
import { FormControlLabel } from "@material-ui/core";
import { cropImage } from "../../api";
import "./styles.css";

const ImagesDialog = (props) => {
  const { image, post } = props;

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onSubmit = async (values) => {
    if (values.changeImage === true) {
      await cropImage({
        ...croppedAreaPixels,
        path: image.src.path,
        bucket: image.src.bucket,
      })
        .then((response) => {
          const payload = { ...values, src: response.data.path };
          props.updateImage(payload);
        })
        .finally(() => {
          setTimeout(() => props.fetchImages({ post_id: post.id }), 2000);
        });
    } else {
      const payload = {
        id: values.id,
        credit: values.credit,
        description: values.description,
      };
      props.updateImage(payload);
    }
  };

  const onCropComplete = (_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  return (
    <Dialog
      onClose={() => props.closeImageDialog()}
      open={props.open}
      fullWidth
      maxWidth={false}
    >
      <DialogTitle onClose={() => props.closeImageDialog()}>
        Editar imagem
      </DialogTitle>
      <DialogContent dividers>
        {image.src && (
          <div style={{ position: "relative", height: 600, marginBottom: 32 }}>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <Cropper
                image={image.src.url}
                crop={crop}
                aspect={4 / 3}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
              />
            </div>
          </div>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Formik
              initialValues={image}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ isValid, isSubmitting }) => (
                <Form autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        multiline
                        label="Descrição"
                        name="description"
                        variant="outlined"
                        fullWidth
                        component={TextField}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        label="Crédito"
                        name="credit"
                        variant="outlined"
                        fullWidth
                        size="small"
                        component={TextField}
                      />
                    </Grid>
                    <Grid item md={12} align="right">
                      <FormControlLabel
                        control={
                          <Field
                            name="changeImage"
                            color="primary"
                            component={Switch}
                          />
                        }
                        label="Alterar imagem"
                      />
                      <Button
                        variant="outlined"
                        color="primary"
                        type="submit"
                        disabled={!isValid || isSubmitting}
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  open: state.image.openDialog,
  image: state.image.form,
  post: state.post.form,
});

const actions = {
  updateImage,
  closeImageDialog,
  fetchImages,
};

export default connect(mapStateToProps, actions)(ImagesDialog);
