import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch, Redirect } from "react-router";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { insertToken } from "api";
import { push } from "connected-react-router";
import _ from "lodash";
import axios from "axios";
import MomentUtils from "@date-io/moment";
import Category from "components/Category";
import Client from "components/Client";
import Event from "components/Event";
import Featured from "components/Featured";
import Login from "components/Login";
import Account from "components/Account";
import Movie from "components/Movie";
import Newspaper from "components/Newspaper";
import Post from "components/Post";
import User from "components/User";
import Video from "components/Video";
import Live from "components/Live";
import Comment from "components/Comment";
import ImageList from "components/Image/List";
import { AccountProvider } from "contexts/account";
import "moment/locale/pt-br";

import configureStore, { history } from "store";

const store = configureStore();

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.setItem("access-token", "");
      store.dispatch(push("/login"));
    } else {
      return Promise.reject(error);
    }
  }
);

insertToken();

const isAuthenticated = () => !_.isEmpty(localStorage.getItem("access-token"));

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    render={(props) =>
      isAuthenticated() ? (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      ) : (
        <Component {...props} />
      )
    }
    {...rest}
  />
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
    {...rest}
  />
);

export default function App() {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <AccountProvider>
            <Switch>
              <LoginRoute path="/login" component={Login} />
              <PrivateRoute path="/account" component={Account} />
              <PrivateRoute path="/featured" component={Featured} />
              <PrivateRoute path="/categories" component={Category} />
              <PrivateRoute path="/posts" component={Post} />
              <PrivateRoute path="/videos" component={Video} />
              <PrivateRoute path="/users" component={User} />
              <PrivateRoute path="/clients" component={Client} />
              <PrivateRoute path="/newspapers" component={Newspaper} />
              <PrivateRoute path="/events" component={Event} />
              <PrivateRoute path="/movies" component={Movie} />
              <PrivateRoute path="/lives" component={Live} />
              <PrivateRoute path="/comments" component={Comment} />
              <PrivateRoute path="/images" component={ImageList} />
              <Route path="/" component={() => <Redirect to="/posts" />} />
              <Route render={() => <div>Página não encontrada.</div>} />
            </Switch>
          </AccountProvider>
        </ConnectedRouter>
      </Provider>
    </MuiPickersUtilsProvider>
  );
}
