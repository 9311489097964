import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { fetchUsers } from "store/actions/user";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Paper,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@material-ui/core";
import Paginator from "utils/Paginator";
import Filter from "./Filter";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: 20,
  },
  inline: {
    display: "inline",
  },
}));

const roleName = (role) => {
  return {
    "admin": "Administrador",
    "editor": "Redator",
    "blogger": "Blogueiro",
  }[role]
};

const getInitials = (name) => {
  let initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  return initials;
};

const UserList = (props) => {
  const classes = useStyles();

  const [filter, setFilter] = useState({});
  const [page, setPage] = useState(0);

  const [users, total, search] = useSelector((state) => [
    state.user.collection,
    state.user.total,
    state.user.search,
  ]);

  const on = useDispatch();

  useEffect(() => {
    on(fetchUsers());
  }, [on]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    on(fetchUsers({ search, ...filter, page: newPage + 1 }));
  };

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <div className={classes.toolbar}>
          <Typography variant="h6">Usuários</Typography>
          <Button
            variant="outlined"
            onClick={() => on(push("/users/new"))}
            style={{ marginLeft: 10 }}
          >
            Adicionar
          </Button>
        </div>
      </Toolbar>
      <Filter setFilter={setFilter} setPage={setPage} />
      <List component="nav" disablePadding>
        {users.map((user) => (
          <ListItem
            button
            onClick={() => on(push(`/users/${user.id}`))}
            key={user.id}
          >
            <ListItemAvatar>
              <Avatar>
                {getInitials(user.name)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={user.name} secondary={roleName(user.role)} />
          </ListItem>
        ))}
      </List>
      <Paginator
        page={page}
        total={total}
        handleChangePage={handleChangePage}
      />
    </Paper>
  );
};

export default UserList;
