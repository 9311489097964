import * as definitions from 'store/definitions/client';

export function fetchClients(filter) {
  return {
    type: definitions.FETCH_CLIENTS,
    payload: filter,
  };
}

export function fetchClient(id) {
  return {
    type: definitions.FETCH_CLIENT,
    payload: id,
  };
}

export function createClient(data) {
  return {
    type: definitions.CREATE_CLIENT,
    payload: data,
  };
}

export function updateClient(data) {
  return {
    type: definitions.UPDATE_CLIENT,
    payload: data,
  };
}
