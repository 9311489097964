import React, { useState, useEffect, useCallback } from "react";
import { fetch } from "api";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Chip,
  Paper,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import Paginator from "utils/Paginator";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: 20,
  },
}));

const LiveList = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const loadData = useCallback(({ page }) => {
    fetch("lives", { page }).then((res) => {
      setData(res.data);
      setTotal(res.total);
      setTotal(res.total);
    });
  }, []);

  useEffect(() => {
    loadData({ page: 1 });
  }, [loadData]);

  const handleChangePage = (item, newPage) => {
    setPage(newPage);
    loadData({ page: newPage + 1 });
  };

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <div className={classes.toolbar}>
          <Typography variant="h6">Lives</Typography>
          <Button
            variant="outlined"
            onClick={() => history.push("/lives/new")}
            style={{ marginLeft: 10 }}
          >
            Adicionar
          </Button>
        </div>
      </Toolbar>
      <List component="nav">
        {data.map((item) => (
          <ListItem
            button
            onClick={() => history.push(`/lives/${item.id}`)}
            key={item.id}
          >
            <ListItemText primary={item.title} secondary="" />
            <ListItemSecondaryAction>
              <Chip
                size="small"
                color="default"
                label={item.clientName}
                style={{ marginRight: 12 }}
              />
              <Chip
                size="small"
                color={item.isActive ? "primary" : "secondary"}
                label={item.isActive ? "Ativo" : "Inativo"}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Paginator
        page={page}
        total={total}
        handleChangePage={handleChangePage}
      />
    </Paper>
  );
};

export default LiveList;
