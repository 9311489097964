import * as definitions from 'store/definitions/user';

export function fetchUsers(filter) {
  return {
    type: definitions.FETCH_USERS,
    payload: filter,
  };
}

export function fetchUser(id) {
  return {
    type: definitions.FETCH_USER,
    payload: id,
  };
}

export function createUser(data) {
  return {
    type: definitions.CREATE_USER,
    payload: data,
  };
}

export function updateUser(data) {
  return {
    type: definitions.UPDATE_USER,
    payload: data,
  };
}