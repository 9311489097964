import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchImages } from 'store/actions/image';
import { find } from 'api';
import { thumbUrl } from 'helpers/thumb';
import {
  Card,
  CardContent,
  Typography,
  Button,
} from '@material-ui/core';
import ImagesDialog from './ImagesDialog';

const Image = (props) => {
  const [image, setImage] = useState(null);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const { imageId } = props;

  useEffect(() => {
    if (imageId) find('images', imageId).then(res => setImage(res));
  }, [imageId]);

  const handleOpenImageDialog = () => {
    props.fetchImages({ post_id: props.postId });
    setOpenImageDialog(true);
  }

  const getImageUrl = () => {
    if ((props.imageId && image)) {
      return thumbUrl(
        image.src.app,
        image.src.path,
        400,
        230,
      );
    }
    return '/blank.jpg';
  }

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom>
          Imagem destaque
        </Typography>
        <div style={{ position: 'relative' }}>
          <img
            src={getImageUrl()}
            style={{ maxWidth: '100%' }}
            alt="Imagem destaque"
          />
          <Button
            variant="contained"
            color="inherit"
            size="small"
            onClick={handleOpenImageDialog}
            style={{
              position: 'absolute',
              bottom: 14,
              right: 10,
            }}
          >
            Editar
          </Button>
        </div>
      </CardContent>
      <ImagesDialog
        open={openImageDialog}
        setFieldValue={props.setFieldValue}
        handleClose={() => setOpenImageDialog(false)}
      />
    </Card>
  );
}

export default connect(null, { fetchImages })(Image);
