import React, { useState, useEffect } from "react";
import { find, create, update } from "api";
import { useHistory } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { TextField, Switch } from "formik-material-ui";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";

const initialValues = {
  title: "",
  embed: "",
  url: "",
  isActive: true,
};

const LiveForm = (props) => {
  const { match } = props;
  const history = useHistory();

  const [movie, setMovie] = useState(initialValues);

  useEffect(() => {
    const { params } = match;
    if (params.id) {
      find("lives", params.id).then((res) => setMovie(res));
    }
  }, [match]);

  const onSubmit = async (values) => {
    const { params } = match;
    if (params.id) {
      update("lives", values.id, { live: values }).then(() =>
        history.push("/lives")
      );
    } else {
      create("lives", { live: values }).then(() => history.push("/lives"));
    }
  };

  return (
    <Card>
      <CardContent>
        <Formik initialValues={movie} onSubmit={onSubmit} enableReinitialize>
          {({ isValid, isSubmitting }) => (
            <Form autoComplete="off">
              <Box display="flex" justifyContent="center">
                <Grid container spacing={3} style={{ maxWidth: 600 }}>
                  <Grid item align="center" xs={12}>
                    <Typography
                      variant="h6"
                      style={{ margin: "12px 0 24px 0" }}
                    >
                      {!match.params.id ? "Adicionar" : "Editar"} live
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="Título"
                      name="title"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="Embed"
                      name="embed"
                      rows="4"
                      variant="outlined"
                      multiline
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="URL"
                      name="url"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      label="Ativo"
                      control={
                        <Field
                          name="isActive"
                          color="primary"
                          component={Switch}
                        />
                      }
                    />
                  </Grid>
                  <Grid item align="right" xs={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => history.push("/lives")}
                      style={{ marginRight: 24 }}
                    >
                      Voltar
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!isValid || isSubmitting}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default LiveForm;
