import * as definitions from "store/definitions/category";
import { LOCATION_CHANGE } from "connected-react-router";

const formState = {
  id: "",
  kind: "",
  name: "",
  description: "",
  order: "",
  isBlog: false,
  isArchived: false,
  isActive: false,
};

const initialState = {
  collection: [],
  total: 0,
  currentPage: 1,
  search: "",
  form: formState,
};

function Category(state = initialState, action) {
  switch (action.type) {
    case definitions.FETCH_CATEGORIES_FULFILLED: {
      return {
        ...state,
        collection: action.payload.data,
        total: action.payload.total,
        currentPage: action.payload.currentPage,
        search: action.payload.search,
      };
    }
    case definitions.FETCH_CATEGORY_FULFILLED: {
      return {
        ...state,
        form: action.payload,
      };
    }
    case LOCATION_CHANGE:
      return {
        ...state,
        form: formState,
      };
    default:
      return state;
  }
}

export default Category;
