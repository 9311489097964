import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export function fetch() {
  return axios
          .get(`${apiUrl}/config`)
          .then(res => res.data);
}

export function update(record) {
  return axios
          .patch(`${apiUrl}/config`, record)
          .then(res => res.data);
}