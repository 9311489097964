import React from "react";
import Textarea from "react-textarea-autosize";
import { useDrop, useDrag } from "react-dnd";
import { thumbUrl } from "helpers/thumb";
import { update } from "api";
import "./styles.css";

const styles = {
  figure: {
    position: "relative",
    margin: 0,
  },
  img: {
    objectFit: "cover",
    display: "block",
    maxWidth: "100%",
    height: "auto",
    backgroundColor: "#f5f5f5",
  },
};

const Image = ({ data = { post: null }, items, setItems, big }) => {
  const { post } = data;

  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    if (post)
      setValue(post.summary || post.title);
  }, [post]);

  const [, drop] = useDrop({
    accept: "box",
    drop: () => ({ ...data }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    item: { ...data, type: "box" },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        const itemsCopy = [...items];
        const sourceIndex = itemsCopy.findIndex((i) => i.id === item.id);
        const targetIndex = itemsCopy.findIndex((i) => i.id === dropResult.id);
        itemsCopy[targetIndex].post = item.post;
        itemsCopy[sourceIndex].post = dropResult.post;
        setItems(itemsCopy);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  const size = big ? 650 : 400;

  const getThumbUrl = () => {
    if (!data || !data.post || !data.post.image) return null;
    return thumbUrl(
      data.post.image.src.app,
      data.post.image.src.path,
      size,
      size
    );
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = (e) => {
    const copy = [...items];
    const index = copy.indexOf(data);
    copy[index].post.summary = value;
    setItems(copy);
    update("posts", data.post.id, { summary: value });
  };

  return (
    <div ref={drop}>
      <div ref={drag} style={{ opacity }}>
        <figure style={styles.figure}>
          <img
            src={getThumbUrl()}
            alt=""
            style={styles.img}
            width={size}
            height={size}
          />
          <figcaption className="caption">
            <Textarea
              value={value}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Digite o texto aqui"
              className={`text ${big ? "big" : "normal"}`}
            />
          </figcaption>
        </figure>
      </div>
    </div>
  );
};

export default Image;
