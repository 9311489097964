export const FETCH_VIDEOS = 'FETCH_VIDEOS';
export const FETCH_VIDEOS_FULFILLED = 'FETCH_VIDEOS_FULFILLED';
export const FETCH_VIDEOS_REJECTED = 'FETCH_VIDEOS_REJECTED';

export const FETCH_VIDEO = 'FETCH_VIDEO';
export const FETCH_VIDEO_FULFILLED = 'FETCH_VIDEO_FULFILLED';
export const FETCH_VIDEO_REJECTED = 'FETCH_VIDEO_REJECTED';

export const CREATE_VIDEO = 'CREATE_VIDEO';
export const CREATE_VIDEO_FULFILLED = 'CREATE_VIDEO_FULFILLED';
export const CREATE_VIDEO_REJECTED = 'CREATE_VIDEO_REJECTED';

export const UPDATE_VIDEO = 'UPDATE_VIDEO';
export const UPDATE_VIDEO_FULFILLED = 'UPDATE_VIDEO_FULFILLED';
export const UPDATE_VIDEO_REJECTED = 'UPDATE_VIDEO_REJECTED';
