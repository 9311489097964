import { push } from 'connected-react-router';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as api from 'api';
import * as definitions from 'store/definitions/movie';

export function* fetchMovies(action) {
  try {
    const filter = action.payload;
    const payload = yield call(api.fetch, 'movies', filter);
    yield put({ type: definitions.FETCH_MOVIES_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_MOVIES_REJECTED, error });
  }
}

export function* fetchMovie(action) {
  try {
    const id = action.payload;
    const payload = yield call(api.find, 'movies', id);
    yield put({ type: definitions.FETCH_MOVIE_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_MOVIE_REJECTED, error });
  }
}

export function* createMovie(action) {
  try {
    const { data, image } = action.payload;
    const payload = yield call(api.create, 'movies', { movie: data });
    yield call(api.upload, 'movies', payload.id, image);
    yield put({ type: definitions.CREATE_MOVIE_FULFILLED, payload });
    yield put(push('/movies'));
  } catch (error) {
    yield put({ type: definitions.CREATE_MOVIE_REJECTED, error });
  }
}

export function* updateMovie(action) {
  try {
    const { data, image } = action.payload;
    const { id } = data;
    const payload = yield call(api.update, 'movies', id, { movie: data });
    if (image) yield call(api.upload, 'movies', id, image);
    // yield call(api.clearSiteCache);
    yield put({ type: definitions.UPDATE_MOVIE_FULFILLED, payload });
    yield put(push('/movies'));
  } catch (error) {
    yield put({ type: definitions.UPDATE_MOVIE_REJECTED, error });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.FETCH_MOVIES, fetchMovies),
    takeLatest(definitions.CREATE_MOVIE, createMovie),
    takeLatest(definitions.FETCH_MOVIE, fetchMovie),
    takeLatest(definitions.UPDATE_MOVIE, updateMovie),
  ]);
}