import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { openImageDialog } from 'store/actions/image';
import { thumbUrl } from 'helpers/thumb';
import {
  Button,
  Dialog,
  Grid,
  IconButton,
} from '@material-ui/core';
import {
  DialogTitle,
  DialogContent,
  DialogActions
} from 'utils/CustomDialog';
import ImagesOptions from 'components/Image/Options';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

const GalleryDialog = (props) => {
  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    if (props.imageIds.length > 0) setSelectedImages(props.imageIds);
  }, [props.imageIds]);

  const selectImage = (image) => {
    if (selectedImages.indexOf(image.id) === -1) {
      setSelectedImages([...selectedImages, image.id]);
    } else {
      setSelectedImages(selectedImages.filter(i => i !== image.id));
    }
  }

  const getSelectedNumber = (image) => {
    const index = selectedImages.indexOf(image.id);
    if (index !== -1) return index + 1;
    return '';
  }

  const handleSave = () => {
    props.setFieldValue('imageIds', selectedImages);
    props.handleClose(false);
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={() => props.handleClose(false)}
      open={props.open}
    >
      <DialogTitle onClose={() => props.handleClose()}>
        Galeria
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <ImagesOptions />
          </Grid>
          {props.images.map(image => (
            <Grid item md={3} key={image.id}>
              <div
                className={selectedImages.includes(image.id) ? 'image-selected' : ''}
                selectednumber={getSelectedNumber(image)}
                style={{ position: 'relative' }}
              >
                <img
                  alt={image.description}
                  src={thumbUrl(image.src.app, image.src.path, 600, 400)}
                  style={{ maxWidth: '100%' }}
                />
                <div style={{ position: 'absolute', top: 5, left: 5, backgroundColor: '#ffffff', borderRadius: 25 }}>
                  <IconButton
                    size="small"
                    onClick={() => props.openImageDialog(image.id)}
                  >
                    <EditIcon />
                  </IconButton>
                </div>
                <div style={{ position: 'absolute', top: 5, right: 5, backgroundColor: '#ffffff', borderRadius: 25 }}>
                  <IconButton
                    size="small"
                    onClick={() => selectImage(image)}
                  >
                    {selectedImages.includes(image.id) ? <CloseIcon /> : <CheckIcon />}
                  </IconButton>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleSave()}
          color="primary"
          variant="contained"
        >
          Inserir
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  imageIds: state.post.form.imageIds,
  images: state.image.collection,
});

const actions = {
  openImageDialog,
};

export default connect(mapStateToProps, actions)(GalleryDialog);
