export const FETCH_NEWSPAPERS = 'FETCH_NEWSPAPERS';
export const FETCH_NEWSPAPERS_FULFILLED = 'FETCH_NEWSPAPERS_FULFILLED';
export const FETCH_NEWSPAPERS_REJECTED = 'FETCH_NEWSPAPERS_REJECTED';

export const FETCH_NEWSPAPER = 'FETCH_NEWSPAPER';
export const FETCH_NEWSPAPER_FULFILLED = 'FETCH_NEWSPAPER_FULFILLED';
export const FETCH_NEWSPAPER_REJECTED = 'FETCH_NEWSPAPER_REJECTED';

export const CREATE_NEWSPAPER = 'CREATE_NEWSPAPER';
export const CREATE_NEWSPAPER_FULFILLED = 'CREATE_NEWSPAPER_FULFILLED';
export const CREATE_NEWSPAPER_REJECTED = 'CREATE_NEWSPAPER_REJECTED';

export const UPDATE_NEWSPAPER = 'UPDATE_NEWSPAPER';
export const UPDATE_NEWSPAPER_FULFILLED = 'UPDATE_NEWSPAPER_FULFILLED';
export const UPDATE_NEWSPAPER_REJECTED = 'UPDATE_NEWSPAPER_REJECTED';
