import * as definitions from "store/definitions/user";
import { LOCATION_CHANGE } from "connected-react-router";

const formState = {
  id: null,
  name: "",
  email: "",
  password: "",
  password_confirmation: "",
  currentClientId: "",
  role: "",
  categoryId: "",
  currentClient: null,
  clientIds: [],
  clients: [],
};

const initialState = {
  collection: [],
  total: 0,
  currentPage: 1,
  search: "",
  form: formState,
};

function User(state = initialState, action) {
  switch (action.type) {
    case definitions.FETCH_USERS_FULFILLED: {
      return {
        ...state,
        collection: action.payload.data,
        total: action.payload.total,
        currentPage: action.payload.currentPage,
        search: action.payload.search,
      };
    }
    case definitions.FETCH_USER_FULFILLED: {
      return {
        ...state,
        form: action.payload,
      };
    }
    case LOCATION_CHANGE:
      return {
        ...state,
        form: formState,
      };
    default:
      return state;
  }
}

export default User;
