import React from "react";
import { fetch } from "api";
import _ from "lodash";

const AccountContext = React.createContext();

const isAuthenticated = () => !_.isEmpty(localStorage.getItem("access-token"));

const AccountProvider = ({ children }) => {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    if (isAuthenticated()) fetch("account").then((res) => setData(res));
  }, []);

  const isAdmin = data && data.role === "admin";
  const isEditor = data && data.role === "editor";
  const isBlogger = data && data.role === "blogger";

  const isCadaminuto =
    data && data.currentClient && data.currentClient.name === "Cadaminuto";
  const isMinutoSertao =
    data && data.currentClient && data.currentClient.name === "Minuto Sertão";

  const ready = data !== null;

  const value = {
    data,
    setData,
    ready,
    isAdmin,
    isEditor,
    isBlogger,
    isCadaminuto,
    isMinutoSertao,
  };

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};

export default AccountContext;

export { AccountProvider };
