import { push } from 'connected-react-router';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as api from 'api';
import * as definitions from 'store/definitions/event';

export function* fetchEvents(action) {
  try {
    const filter = action.payload;
    const payload = yield call(api.fetch, 'events', filter);
    yield put({ type: definitions.FETCH_EVENTS_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_EVENTS_REJECTED, error });
  }
}

export function* fetchEvent(action) {
  try {
    const id = action.payload;
    const payload = yield call(api.find, 'events', id);
    yield put({ type: definitions.FETCH_EVENT_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_EVENT_REJECTED, error });
  }
}

export function* createEvent(action) {
  try {
    const { data, image } = action.payload;
    const payload = yield call(api.create, 'events', { event: data });
    yield call(api.upload, 'events', payload.id, image);
    yield put({ type: definitions.CREATE_EVENT_FULFILLED, payload });
    yield put(push('/events'));
  } catch (error) {
    yield put({ type: definitions.CREATE_EVENT_REJECTED, error });
  }
}

export function* updateEvent(action) {
  try {
    const { data, image } = action.payload;
    const { id } = data;
    const payload = yield call(api.update, 'events', id, { event: data });
    if (image) yield call(api.upload, 'events', id, image);
    // yield call(api.clearSiteCache);
    yield put({ type: definitions.UPDATE_EVENT_FULFILLED, payload });
    yield put(push('/events'));
  } catch (error) {
    yield put({ type: definitions.UPDATE_EVENT_REJECTED, error });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.FETCH_EVENTS, fetchEvents),
    takeLatest(definitions.CREATE_EVENT, createEvent),
    takeLatest(definitions.FETCH_EVENT, fetchEvent),
    takeLatest(definitions.UPDATE_EVENT, updateEvent),
  ]);
}