import * as definitions from 'store/definitions/config';

const formState = {
  id: null,
  template: '',
};

const initialState = {
  collection: [],
  form: formState,
  openDialog: false,
};

function Config(state = initialState, action) {
  switch (action.type) {
    case definitions.OPEN_CONFIG_DIALOG_FULFILLED: {
      return {
        ...state,
        form: action.payload,
        openDialog: true,
      };
    }
    case definitions.CLOSE_CONFIG_DIALOG: {
      return {
        ...state,
        openDialog: false,
      };
    }
    case definitions.UPDATE_CONFIG_FULFILLED: {
      return {
        ...state,
        form: action.payload,
        openDialog: false,
      };
    }
    default:
      return state;
  }
};

export default Config;