import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { openImageDialog, removeImage } from "store/actions/image";
import { thumbUrl } from "helpers/thumb";
import { Button, Dialog, Grid, IconButton } from "@material-ui/core";
import { DialogTitle, DialogContent, DialogActions } from "utils/CustomDialog";
import ImagesOptions from "components/Image/Options";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";

const ImagesDialog = (props) => {
  const [selectedImageId, setSelectedImageId] = useState(null);

  useEffect(() => {
    if (props.imageId) setSelectedImageId(props.imageId);
  }, [props.imageId]);

  const handleSelectImage = (imageId) => {
    if (selectedImageId === imageId) return setSelectedImageId(null);
    setSelectedImageId(imageId);
  };

  const handleSave = () => {
    props.setFieldValue("imageId", selectedImageId);
    props.handleClose();
  };

  return (
    <Dialog
      onClose={props.handleClose}
      open={props.open}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle onClose={props.handleClose}>Imagens</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <ImagesOptions />
          </Grid>
          {props.images.map((image) => (
            <Grid item md={3} key={image.id}>
              <div
                className={selectedImageId === image.id ? "image-selected" : ""}
                style={{ position: "relative" }}
              >
                <img
                  src={thumbUrl(image.src.app, image.src.path, 600, 400)}
                  alt={image.description}
                  style={{ maxWidth: "100%" }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 5,
                    left: 5,
                    backgroundColor: "#ffffff",
                    borderRadius: 25,
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={() => props.openImageDialog(image.id)}
                  >
                    <EditIcon />
                  </IconButton>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 5,
                    left: 45,
                    backgroundColor: "#ffffff",
                    borderRadius: 25,
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={() => props.removeImage(image.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 5,
                    backgroundColor: "#ffffff",
                    borderRadius: 25,
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={() => handleSelectImage(image.id)}
                  >
                    {selectedImageId === image.id ? (
                      <CloseIcon />
                    ) : (
                      <CheckIcon />
                    )}
                  </IconButton>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleSave()}
          color="primary"
          variant="contained"
        >
          Inserir
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  images: state.image.collection,
  imageId: state.post.form.imageId,
});

const actions = {
  openImageDialog,
  removeImage,
};

export default connect(mapStateToProps, actions)(ImagesDialog);
