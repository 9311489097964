import React from "react";
import Textarea from "react-textarea-autosize";
import { Typography } from "@material-ui/core";
import { useDrop, useDrag } from "react-dnd";
import { update } from "api";

const styles = {
  title: {
    color: "#e10015",
    display: "block",
    fontWeight: 500,
    fontSize: 35,
    lineHeight: "41px",
    textAlign: "center",
    padding: 0,
    margin: "0 0 20px 0",
  },
  subtitle: {
    fontSize: 18,
    color: "#7d7d7d",
    textAlign: "center",
  },
};

const Text = ({ data = { post: null }, items, setItems }) => {
  const { post } = data;
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    if (post)
      setValue(post.summary || post.title);
  }, [post]);

  const [, drop] = useDrop({
    accept: 'box',
    drop: () => ({ ...data }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    item: { ...data, type: 'box' },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        const itemsCopy = [ ...items ];
        const sourceIndex = itemsCopy.findIndex(i => i.id === item.id);
        const targetIndex = itemsCopy.findIndex(i => i.id === dropResult.id);
        itemsCopy[targetIndex].post = item.post;
        itemsCopy[sourceIndex].post = dropResult.post;
        setItems(itemsCopy);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = (e) => {
    const copy = [...items];
    const index = copy.indexOf(data);
    copy[index].post.summary = value;
    setItems(copy);
    update("posts", data.post.id, { summary: value });
  };

  return (
    <div ref={drop}>
      <div ref={drag} style={{ opacity }}>
        <Textarea
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Digite o texto aqui"
          style={styles.title}
          className="text2"
        />
        <Typography
          style={styles.subtitle}
          gutterBottom
        >
          {(data && data.post) ? data.post.mustache : 'Arraste aqui.'}
        </Typography>
      </div>
    </div>
  );
};

export default Text;
