import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function Tags({ values, setFieldValue }) {
  const classes = useStyles();

  const [tag, setTag] = React.useState("");
  const [tags, setTags] = React.useState([]);

  React.useEffect(() => {
    setTags(values.tags.split(",").filter((t) => t));
  }, [values.tags]);

  const handleDelete = (chipToDelete) => () => {
    const payload = tags.filter((t) => t !== chipToDelete);
    setFieldValue("tags", payload.join(","));
  };

  const handleChange = (e) => {
    setTag(e.target.value);
  };

  const handleAdd = (e) => {
    if (e.key === "Enter") {
      const payload = [...tags, tag];
      setFieldValue("tags", payload.join(","));
      setTag("");
      e.preventDefault();
    }
  };

  return (
    <Card>
      <CardContent style={{ paddingBottom: 16 }}>
        <Typography gutterBottom>Tags</Typography>
        <TextField
          placeholder="Digite aqui uma tag"
          value={tag}
          onChange={handleChange}
          onKeyPress={handleAdd}
          variant="outlined"
          size="small"
          fullWidth
          style={{ marginBottom: 12 }}
        />
        <ul className={classes.root}>
          {tags.map((item) => {
            return (
              <li key={item}>
                <Chip
                  label={item}
                  onDelete={handleDelete(item)}
                  className={classes.chip}
                />
              </li>
            );
          })}
        </ul>
      </CardContent>
    </Card>
  );
}
