import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { Grid } from "@material-ui/core";
import { fetchPost, createPost, updatePost } from "store/actions/post";
import Textarea from "react-textarea-autosize";
import Sidebar from "./Sidebar";
import ImageEdit from "components/Image/Edit";
import AccountContext from "contexts/account";
import "./styles.css";

import CKEditor from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/pt-br";
import moment from "moment";

const editorConfig = {
  toolbar: [
    "Bold",
    "Italic",
    "Strikethrough",
    "Underline",
    "|",
    "FontSize",
    "FontColor",
    "FontBackgroundColor",
    "|",
    "Alignment",
    "|",
    "outdent",
    "indent",
    "|",
    "BlockQuote",
    "|",
    "link",
    "|",
    "bulletedList",
    "numberedList",
    "|",
    "mediaEmbed",
    "|",
    "undo",
    "redo",
  ],
  fontSize: {
    options: ["default", 8, 9, 10, 11, 12, 14, 18, 24, 30],
  },
  language: "pt-br",
  image: {
    toolbar: [
      "imageStyle:alignLeft",
      "imageStyle:full",
      "imageStyle:alignRight",
    ],
    styles: ["full", "alignLeft", "alignRight"],
  },
  placeholder: "Digite o texto",
  mediaEmbed: {
    previewsInData: true,
  },
};

const PostForm = (props) => {
  const account = React.useContext(AccountContext);
  const [editor, setEditor] = useState(null);
  const { fetchPost, post = {}, match } = props;

  useEffect(() => {
    const { params } = match;
    if (params.id) fetchPost(params.id);
  }, [fetchPost, match]);

  const onSubmit = (values, { setSubmitting }) => {
    if (!values.scheduledTo) {
      setSubmitting(false);
      return alert("Por favor, selecione um horário.");
    }
    const scheduledTo = moment(values.scheduledTo);
    if (!scheduledTo.isValid()) {
      setSubmitting(false);
      return alert("Por favor, selecione um horário válido.");
    }
    if (!values.title) {
      setSubmitting(false);
      return alert("Por favor, digite um título.");
    }
    if (!account.isBlogger && !values.categoryId) {
      setSubmitting(false);
      return alert("Por favor, selecione uma categoria.");
    }
    const { params } = props.match;
    if (params.id) {
      props.updatePost(values);
    } else {
      props.createPost(values);
    }
  };

  return (
    <>
      <Formik initialValues={post} onSubmit={onSubmit} enableReinitialize>
        {({
          handleBlur,
          handleChange,
          isSubmitting,
          isValid,
          setFieldValue,
          values,
        }) => (
          <Form autoComplete="off">
            <Grid container spacing={3}>
              <Grid item md={9}>
                <div style={{ backgroundColor: "white", paddingBottom: 20 }}>
                  <div className="postContent">
                    <Textarea
                      className="inputInline title"
                      name="title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Digite o título"
                      value={values.title}
                    />
                    <Textarea
                      className="inputInline summary"
                      name="summary"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Digite o título reduzido"
                      value={values.summary}
                    />
                    <Textarea
                      className="inputInline mustache"
                      name="mustache"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Digite o subtítulo"
                      value={values.mustache}
                    />
                    <CKEditor
                      editor={DecoupledEditor}
                      config={editorConfig}
                      data={values.body}
                      onInit={(editor) => {
                        setEditor(editor);
                        editor.ui
                          .getEditableElement()
                          .parentElement.insertBefore(
                            editor.ui.view.toolbar.element,
                            editor.ui.getEditableElement()
                          );
                      }}
                      onChange={(event, editor) =>
                        setFieldValue("body", editor.getData())
                      }
                    />
                  </div>
                </div>
              </Grid>
              <Grid item md={3}>
                <Sidebar
                  editor={editor}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <ImageEdit />
    </>
  );
};

const mapStateToProps = (state) => ({
  post: state.post.form,
});

const actions = {
  fetchPost,
  createPost,
  updatePost,
};

export default connect(mapStateToProps, actions)(PostForm);
