import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchNewspapers } from 'store/actions/newspaper';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Chip,
  Paper,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import Paginator from 'utils/Paginator';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: 20,
  },
}));

const NewspaperList = (props) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);

  const [newspapers, total, search] = useSelector(state => [
    state.newspaper.collection,
    state.newspaper.total,
    state.newspaper.search,
  ])

  const on = useDispatch();

  useEffect(() => {
    on(fetchNewspapers());
  }, [on]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    on(fetchNewspapers({ search, page: newPage + 1 }));
  };

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <div className={classes.toolbar}>
          <Typography variant="h6">
            Press
          </Typography>
          <Button
            variant="outlined"
            onClick={() => on(push('/newspapers/new'))}
            style={{ marginLeft: 10 }}
          >
            Adicionar
          </Button>
        </div>
      </Toolbar>
      <List component="nav">
        {newspapers.map(newspaper => (
          <ListItem
            button
            onClick={() => on(push(`/newspapers/${newspaper.id}`))}
            key={newspaper.id}
          >
            <ListItemText
              primary={newspaper.title}
              secondary={newspaper.url}
            />
            <ListItemSecondaryAction>
              <Chip
                size="small"
                color="default"
                label={newspaper.clientName}
                style={{ marginRight: 12 }}
              />
              <Chip
                size="small"
                color={newspaper.isActive ? 'primary' : 'secondary'}
                label={newspaper.isActive ? 'Ativo' : 'Inativo'}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Paginator
        page={page}
        total={total}
        handleChangePage={handleChangePage}
      />
    </Paper>
  );
};

export default NewspaperList;
