import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchEvents } from 'store/actions/event';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Chip,
  Paper,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import moment from 'moment';
import Paginator from 'utils/Paginator';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: 20,
  },
}));

const EventList = (props) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);

  const [events, total, search] = useSelector(state => [
    state.event.collection,
    state.event.total,
    state.event.search,
  ])

  const on = useDispatch();

  useEffect(() => {
    on(fetchEvents());
  }, [on]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    on(fetchEvents({ search, page: newPage + 1 }));
  };

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <div className={classes.toolbar}>
          <Typography variant="h6">
            Agenda
          </Typography>
          <Button
            variant="outlined"
            onClick={() => on(push('/events/new'))}
            style={{ marginLeft: 10 }}
          >
            Adicionar
          </Button>
        </div>
      </Toolbar>
      <List component="nav">
        {events.map(event => (
          <ListItem
            button
            onClick={() => on(push(`/events/${event.id}`))}
            key={event.id}
          >
            <ListItemText
              primary={event.title}
              secondary={`${event.local} - ${moment(event.date).format('DD/MM/YYYY HH:mm')}`}
            />
            <ListItemSecondaryAction>
              <Chip
                size="small"
                color="default"
                label={event.clientName}
                style={{ marginRight: 12 }}
              />
              <Chip
                size="small"
                color={event.isActive ? 'primary' : 'secondary'}
                label={event.isActive ? 'Ativo' : 'Inativo'}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Paginator
        page={page}
        total={total}
        handleChangePage={handleChangePage}
      />
    </Paper>
  );
};

export default EventList;
