import * as definitions from "store/definitions/image";

const formState = {
  id: null,
  description: "",
  credit: "",
};

const initialState = {
  collection: [],
  total: 0,
  totalPages: 0,
  currentPage: 1,
  search: "",
  form: formState,
  openDialog: false,
};

function Image(state = initialState, action) {
  switch (action.type) {
    case definitions.FETCH_IMAGES_FULFILLED: {
      return {
        ...state,
        collection: action.payload.data,
        total: action.payload.total,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
        search: action.payload.search,
      };
    }
    case definitions.CREATE_IMAGE_FULFILLED: {
      return {
        ...state,
        collection: [action.payload, ...state.collection],
      };
    }
    case definitions.OPEN_IMAGE_DIALOG_FULFILLED: {
      return {
        ...state,
        form: action.payload,
        openDialog: true,
      };
    }
    case definitions.CLOSE_IMAGE_DIALOG:
    case definitions.UPDATE_IMAGE_FULFILLED: {
      return {
        ...state,
        form: formState,
        openDialog: false,
      };
    }
    default:
      return state;
  }
}

export default Image;
