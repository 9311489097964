import { all } from 'redux-saga/effects';

import blogSagas from './blog';
import categorySagas from './category';
import clientSagas from './client';
import configSagas from './config';
import eventSagas from './event';
import imageSagas from './image';
import movieSagas from './movie';
import newspaperSagas from './newspaper';
import postSagas from './post';
import sessionSagas from './session';
import userSagas from './user';
import videoSagas from './video';

export default function* rootSaga() {
  yield all([
    blogSagas(),
    categorySagas(),
    clientSagas(),
    configSagas(),
    eventSagas(),
    imageSagas(),
    movieSagas(),
    newspaperSagas(),
    postSagas(),
    sessionSagas(),
    userSagas(),
    videoSagas(),
  ]);
};