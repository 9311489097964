import React, { useEffect } from "react";
import { fetch } from "api";
import { Field } from "formik";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  MenuItem,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { TextField, Switch } from "formik-material-ui";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccountContext from "contexts/account";
import Image from "./Image";
import Tags from "./Tags";
import Tools from "./Tools";

export default function PostOptions(props) {
  const account = React.useContext(AccountContext);
  const [categories, setCategories] = React.useState([]);

  const { editor, values, isSubmitting } = props;

  useEffect(() => {
    fetch("categories", { isActive: true }).then((res) => setCategories(res));
  }, []);

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Opções</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              fullWidth
              style={{ height: 56 }}
            >
              Publicar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Image
              postId={values.id}
              imageId={values.imageId}
              setFieldValue={props.setFieldValue}
            />
          </Grid>
          <Grid item xs={12}>
            <Tools
              editor={editor}
              values={props.values}
              setFieldValue={props.setFieldValue}
            />
          </Grid>
          <Grid item xs={12}>
            <KeyboardDateTimePicker
              label="Agendamento"
              value={props.values.scheduledTo}
              inputVariant="outlined"
              fullWidth
              ampm={false}
              format="DD/MM/YYYY HH:mm"
              onChange={(e) => props.setFieldValue("scheduledTo", e)}
            />
          </Grid>
          <Grid item xs={12}>
            <Tags values={props.values} setFieldValue={props.setFieldValue} />
          </Grid>
          {!account.isBlogger && (
            <Grid item xs={12}>
              <Field
                select
                label="Categoria"
                name="categoryId"
                variant="outlined"
                fullWidth
                component={TextField}
              >
                {categories.map((category) => (
                  <MenuItem value={category.id} key={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          )}
          {(account.isAdmin || account?.data?.categoryId === 395) && (
            <Grid item xs={12}>
              <Field
                select
                label="Posição"
                name="position"
                variant="outlined"
                fullWidth
                component={TextField}
              >
                <MenuItem value="">Selecione</MenuItem>
                <MenuItem value="rm_1">Destaque 1</MenuItem>
                <MenuItem value="rm_2">Destaque 2</MenuItem>
                <MenuItem value="rm_3">Destaque 3</MenuItem>
              </Field>
            </Grid>
          )}
          <Grid item xs={12}>
            <Field
              label="Chapéu"
              name="hat"
              variant="outlined"
              fullWidth
              component={TextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              label="Crédito"
              name="credit"
              variant="outlined"
              fullWidth
              component={TextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              label="URL avulsa"
              name="url"
              variant="outlined"
              fullWidth
              component={TextField}
            />
          </Grid>
          {account.isAdmin && (
            <Grid item xs={12}>
              <Field
                label="Cache control"
                name="cacheControl"
                variant="outlined"
                fullWidth
                component={TextField}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Field
              label="Link"
              name="link"
              variant="outlined"
              fullWidth
              component={TextField}
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Field
                    name="allowComments"
                    color="primary"
                    component={Switch}
                  />
                }
                label="Permitir comentários"
              />
              <FormControlLabel
                control={
                  <Field
                    name="allowSocialNetwork"
                    color="primary"
                    component={Switch}
                  />
                }
                label="Permitir publicar em mídias sociais"
              />
              <FormControlLabel
                control={
                  <Field name="sponsored" color="primary" component={Switch} />
                }
                label="Conteúdo de marca"
              />
              <FormControlLabel
                control={
                  <Field name="featured" color="primary" component={Switch} />
                }
                label="Destaque"
              />
              <FormControlLabel
                control={
                  <Field name="isActive" color="primary" component={Switch} />
                }
                label="Ativo"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
