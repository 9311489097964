import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as api from 'api/config';
import * as definitions from 'store/definitions/config';

export function* openConfigDialog(action) {
  try {
    const payload = yield call(api.fetch);
    yield put({ type: definitions.OPEN_CONFIG_DIALOG_FULFILLED, payload });
  } catch (error) {
    
  }
}

export function* updateConfig(action) {
  try {
    const payload = yield call(api.update, action.payload);
    yield put({ type: definitions.UPDATE_CONFIG_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.UPDATE_CONFIG_REJECTED, error });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.OPEN_CONFIG_DIALOG, openConfigDialog),
    takeLatest(definitions.UPDATE_CONFIG, updateConfig),
  ]);
}