import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { TextField, Switch } from 'formik-material-ui';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import {
  fetchEvent,
  createEvent,
  updateEvent,
} from 'store/actions/event';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import Image from './Image';

const EventForm = (props) => {
  const { match } = props;

  const [file, setFile] = React.useState(null);

  const [event] = useSelector(state => [state.event.form]);

  const on = useDispatch();

  useEffect(() => {
    const { params } = match;
    if (params.id) on(fetchEvent(params.id));
  }, [on, match]);

  const onSubmit = async values => {
    const { params } = match;
    if (params.id) {
      on(updateEvent(values, file));
    } else {
      on(createEvent(values, file));
    };
  };

  return (
    <Card>
      <CardContent>
        <Formik
          initialValues={event}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, setFieldValue, isValid, isSubmitting}) => (
            <Form autoComplete="off">
              <Box display="flex" justifyContent="center">
                <Grid container spacing={3} style={{ maxWidth: 600 }}>
                  <Grid item align="center" xs={12}>
                    <Typography variant="h6" style={{ margin: '12px 0 24px 0' }}>
                      {!match.params.id ? 'Adicionar' : 'Editar'} evento
                    </Typography>
                    <Image
                      data={event}
                      file={file}
                      setFile={setFile}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="Título"
                      name="title"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="Descrição"
                      name="description"
                      rows="4"
                      variant="outlined"
                      multiline
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="Local"
                      name="local"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <KeyboardDateTimePicker
                      label="Data"
                      value={values.date}
                      inputVariant="outlined"
                      fullWidth
                      ampm={false}
                      format="DD/MM/YYYY HH:mm"
                      onChange={e => setFieldValue('date', e)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      label="Ativo"
                      control={
                        <Field
                          name="isActive"
                          color="primary"
                          component={Switch}
                        />
                      }
                    />
                  </Grid>
                  <Grid item align="right" xs={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      disabled={!isValid || isSubmitting}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default EventForm;
