import * as definitions from "store/definitions/session";

const initialState = {
  user: {},
};

function Session(state = initialState, action) {
  switch (action.type) {
    case definitions.LOGIN_FULFILLED:
      return {
        user: action.payload,
      };
    case definitions.LOGIN_REJECTED:
      alert("E-mail ou a senha estão incorretos.");
      return {
        user: {},
      };
    case definitions.LOGOUT:
      return {
        user: {},
      };
    default:
      return state;
  }
}

export default Session;
