import React, { useEffect } from "react";
import { fetch } from "api"
import { useSelector, useDispatch } from "react-redux";
import { Formik, Field, Form } from "formik";
import { TextField, Select } from "formik-material-ui";
import { fetchClients } from "store/actions/client";
import { fetchUser, createUser, updateUser } from "store/actions/user";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  MenuItem,
} from "@material-ui/core";

const UserForm = (props) => {
  const { match } = props;

  const [categories, setCategories] = React.useState([]);

  const [user, clients] = useSelector((state) => [
    state.user.form,
    state.client.collection,
  ]);

  const on = useDispatch();

  useEffect(() => {
    fetch("categories", { isActive: true, isBlog: true })
      .then(res => setCategories(res));
  }, []);

  useEffect(() => {
    const { params } = match;
    if (params.id) on(fetchUser(params.id));
    on(fetchClients());
  }, [on, match]);

  const onSubmit = async (values) => {
    const { params } = props.match;
    if (params.id) {
      on(updateUser(values));
    } else {
      on(createUser(values));
    }
  };

  return (
    <Card>
      <CardContent>
        <Formik initialValues={user} onSubmit={onSubmit} enableReinitialize>
          {({ values, isValid, isSubmitting }) => (
            <Form autoComplete="off">
              <Box display="flex" justifyContent="center">
                <Grid container spacing={3} style={{ maxWidth: 600 }}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {!match.params.id ? "Adicionar" : "Editar"} usuário
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="Nome"
                      name="name"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="E-mail"
                      name="email"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="Senha"
                      name="password"
                      type="password"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="Confirmação de senha"
                      name="passwordConfirmation"
                      type="password"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Permissão</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      select
                      label="Papel"
                      name="role"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    >
                      <MenuItem value="admin">Administrador</MenuItem>
                      <MenuItem value="editor">Editor</MenuItem>
                      <MenuItem value="blogger">Blogueiro</MenuItem>
                    </Field>
                  </Grid>
                  {values.role === "blogger" && (
                    <Grid item xs={12}>
                      <Field
                        select
                        label="Categoria"
                        name="categoryId"
                        variant="outlined"
                        fullWidth
                        component={TextField}
                      >
                        {categories.map(category => (
                          <MenuItem value={category.id} key={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography variant="h6">Sites permitidos</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      multiple
                      label="Clientes"
                      name="clientIds"
                      variant="outlined"
                      fullWidth
                      component={Select}
                    >
                      {clients.map((client) => (
                        <MenuItem value={client.id} key={client.id}>
                          {client.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Site padrão</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="Site padrão"
                      name="currentClientId"
                      variant="outlined"
                      fullWidth
                      component={Select}
                    >
                      {clients.map((client) => (
                        <MenuItem value={client.id} key={client.id}>
                          {client.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item align="right" xs={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      disabled={!isValid || isSubmitting}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default UserForm;
