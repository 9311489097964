import { push } from 'connected-react-router';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as api from 'api';
import * as definitions from 'store/definitions/category';

export function* fetchCategories(action) {
  try {
    const filter = action.payload;
    const payload = yield call(api.fetch, 'categories', filter);
    yield put({ type: definitions.FETCH_CATEGORIES_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_CATEGORIES_REJECTED, error });
  }
}

export function* fetchCategory(action) {
  try {
    const id = action.payload;
    const payload = yield call(api.find, 'categories', id);
    yield put({ type: definitions.FETCH_CATEGORY_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_CATEGORY_REJECTED, error });
  }
}

export function* createCategory(action) {
  try {
    const { data, image } = action.payload;
    const payload = yield call(api.create, 'categories', { category: data });
    yield call(api.upload, 'categories', payload.id, image);
    yield put({ type: definitions.CREATE_CATEGORY_FULFILLED, payload });
    yield put(push('/categories'));
  } catch (error) {
    yield put({ type: definitions.CREATE_CATEGORY_REJECTED, error });
  }
}

export function* updateCategory(action) {
  try {
    const { data, image } = action.payload;
    const { id } = data;
    const payload = yield call(api.update, 'categories', id, { category: data });
    if (image) yield call(api.upload, 'categories', id, image);
    yield put({ type: definitions.UPDATE_CATEGORY_FULFILLED, payload });
    yield put(push('/categories'));
  } catch (error) {
    yield put({ type: definitions.UPDATE_CATEGORY_REJECTED, error });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.FETCH_CATEGORIES, fetchCategories),
    takeLatest(definitions.FETCH_CATEGORY, fetchCategory),
    takeLatest(definitions.CREATE_CATEGORY, createCategory),
    takeLatest(definitions.UPDATE_CATEGORY, updateCategory),
  ]);
}