import * as definitions from 'store/definitions/event';
import { LOCATION_CHANGE } from 'connected-react-router';

const formState = {
  id: null,
  title: '',
  description: '',
  local: '',
  date: new Date(),
  isActive: false,
};

const initialState = {
  collection: [],
  total: 0,
  currentPage: 1,
  search: '',
  form: formState,
};

function Event(state = initialState, action) {
  switch (action.type) {
    case definitions.FETCH_EVENTS_FULFILLED: {
      return {
        ...state,
        collection: action.payload.data,
        total: action.payload.total,
        currentPage: action.payload.currentPage,
        search: action.payload.search,
      };
    }
    case definitions.FETCH_EVENT_FULFILLED: {
      return {
        ...state,
        form: action.payload,
      };
    }
    case LOCATION_CHANGE:
      return {
        ...state,
        form: formState,
      };
    default:
      return state;
  }
};

export default Event;