import React from 'react';
import {
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import { useDrag } from 'react-dnd';
import { thumbUrl } from 'helpers/thumb';

const Post = ({ data, items, setItems }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { ...data, type: 'box' },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        const itemsCopy = [ ...items ];
        const targetIndex = itemsCopy.findIndex(i => i.id === dropResult.id);
        itemsCopy[targetIndex].post = {
          id: item.id,
          title: item.title,
          mustache: item.mustache,
          image: item.image || null,
        };
        setItems(itemsCopy);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  const getThumbUrl = () => {
    if (!data || !data || !data.image) return `http://placehold.jp/100x100.jpg`;
    return thumbUrl(data.image.src.app, data.image.src.path, 100, 100);
  }

  const canDrag = () => {
    const ids = items.map(i => (i.post) ? i.post.id : -1);
    return ids.includes(data.id);
  }

  return (
    <ListItem
      button
      key={data.id}
      alignItems="flex-start"
      ref={drag}
      style={{ opacity }}
      disabled={canDrag()}
    >
      <ListItemAvatar>
        <Avatar
          variant="square"
          alt=""
          src={getThumbUrl()}
        />
      </ListItemAvatar>
      <ListItemText
        primary={data.summary || data.title}
        secondary={data.clientName}
        style={{ textDecoration: canDrag() ? 'line-through' : 'none' }}
      />
    </ListItem>
  );
};

export default Post;
