import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCategories } from "store/actions/category";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Chip,
  Paper,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Paginator from "utils/Paginator";
import AddIcon from "@material-ui/icons/Add";
import Filter from "./Filter";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    minWidth: 650,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));

const CategoryList = () => {
  const classes = useStyles();

  const history = useHistory();

  const [filter, setFilter] = useState({});
  const [page, setPage] = useState(0);

  const [categories, total, search] = useSelector((state) => [
    state.category.collection,
    state.category.total,
    state.category.search,
  ]);

  const on = useDispatch();

  useEffect(() => {
    on(fetchCategories({ paged: true, isArchived: false }));
  }, [on]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
    on(
      fetchCategories({
        search,
        ...filter,
        page: newPage + 1,
        paged: true,
        isArchived: false,
      })
    );
  };

  return (
    <>
      <Toolbar disableGutters>
        <div className={classes.toolbar}>
          <Typography variant="h6">Categorias</Typography>
          <div>
            <Button
              variant="outlined"
              onClick={() => history.push("/categories/new")}
              style={{ marginLeft: 10 }}
            >
              <AddIcon />
            </Button>
          </div>
        </div>
      </Toolbar>
      <Filter setFilter={setFilter} setPage={setPage} />
      <Paginator
        page={page}
        total={total}
        handleChangePage={handleChangePage}
      />
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Título</TableCell>
              <TableCell width="1%"></TableCell>
              <TableCell width="1%"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category) => (
              <TableRow
                key={category.id}
                style={{
                  cursor: "pointer",
                }}
              >
                <TableCell
                  onClick={() => history.push(`/categories/${category.id}`)}
                >
                  {category.name}
                </TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    color="default"
                    label={category.clientName}
                  />
                </TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    color={category.isActive ? "primary" : "secondary"}
                    label={category.isActive ? "Ativo" : "Inativo"}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Paginator
        page={page}
        total={total}
        handleChangePage={handleChangePage}
      />
    </>
  );
};

export default CategoryList;
