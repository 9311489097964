import React from "react";
import { Route, Switch } from "react-router";
import Layout from "components/Layout";
import List from "./List";
import Form from "./Form";

const User = () => (
  <Layout>
    <Switch>
      <Route exact path="/users" component={List} />
      <Route exact path="/users/new" component={Form} />
      <Route exact path="/users/:id" component={Form} />
    </Switch>
  </Layout>
);

export default User;
