import React from 'react';
import { TablePagination } from '@material-ui/core';

const Paginator = (props) => {
  const { page, total, handleChangePage } = props;
  
  return (
    <TablePagination
      labelRowsPerPage="Items por página"
      rowsPerPageOptions={[]}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} de ${count}`}
      component="div"
      count={total}
      rowsPerPage={25}
      page={page}
      onChangePage={handleChangePage}
    />
  );
};

export default Paginator;
