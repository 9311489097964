import * as definitions from 'store/definitions/post';

export function fetchPosts(filter) {
  return {
    type: definitions.FETCH_POSTS,
    payload: filter,
  };
}

export function fetchPost(id) {
  return {
    type: definitions.FETCH_POST,
    payload: id,
  };
}

export function createPost(data) {
  return {
    type: definitions.CREATE_POST,
    payload: data,
  };
}

export function updatePost(data) {
  return {
    type: definitions.UPDATE_POST,
    payload: data,
  };
}

export function massActionPosts(ids) {
  return {
    type: definitions.MASS_ACTION_POSTS,
    payload: ids,
  };
}
