import React from "react";
import { Typography, CircularProgress } from "@material-ui/core";
import axios from "axios";

export default function Count({ id }) {
  const [count, setCount] = React.useState("-");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (process.env.NODE_ENV !== "production") return;
    setLoading(true);
    axios
      .get(`https://analytics.cadaminuto.com.br/posts/${id}/clicks`)
      .then((res) => {
        setLoading(false);
        setCount(res.data.count * 7);
      });
  }, [id]);

  if (loading) return <CircularProgress size={19} />;

  return <Typography>{count}</Typography>;
}
