import { push } from "connected-react-router";
import { all, call, put, takeLatest } from "redux-saga/effects";
import * as api from "api";
import * as definitions from "store/definitions/post";

export function* fetchPosts(action) {
  try {
    const filter = action.payload;
    const payload = yield call(api.fetch, "posts", filter);
    yield put({ type: definitions.FETCH_POSTS_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_POSTS_REJECTED, error });
  }
}

export function* fetchPost(action) {
  try {
    const id = action.payload;
    const payload = yield call(api.find, "posts", id);
    yield put({ type: definitions.FETCH_POST_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_POST_REJECTED, error });
  }
}

export function* createPost(action) {
  try {
    const payload = yield call(api.create, "posts", { post: action.payload });
    yield put({ type: definitions.CREATE_POST_FULFILLED, payload });
    yield put(push("/posts"));
  } catch (error) {
    yield put({ type: definitions.CREATE_POST_REJECTED, error });
  }
}

export function* updatePost(action) {
  try {
    const { id } = action.payload;
    const payload = yield call(api.update, "posts", id, {
      post: action.payload,
    });
    yield put({ type: definitions.UPDATE_POST_FULFILLED, payload });
    yield put(push("/posts"));
  } catch (error) {
    yield put({ type: definitions.UPDATE_POST_REJECTED, error });
  }
}

export function* massActionPosts(action) {
  try {
    const ids = action.payload;
    const payload = yield call(api.massAction, "posts", ids);
    // yield call(api.clearSiteCache);
    yield put({ type: definitions.MASS_ACTION_POSTS_FULFILLED, payload });
    yield put({ type: definitions.FETCH_POSTS });
  } catch (error) {
    yield put({ type: definitions.MASS_ACTION_POSTS_REJECTED, error });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.FETCH_POSTS, fetchPosts),
    takeLatest(definitions.CREATE_POST, createPost),
    takeLatest(definitions.FETCH_POST, fetchPost),
    takeLatest(definitions.UPDATE_POST, updatePost),
    takeLatest(definitions.MASS_ACTION_POSTS, massActionPosts),
  ]);
}
