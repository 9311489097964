import { push } from 'connected-react-router';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as api from 'api';
import * as definitions from 'store/definitions/video';

export function* fetchVideos(action) {
  try {
    const filter = action.payload;
    const payload = yield call(api.fetch, 'videos', filter);
    yield put({ type: definitions.FETCH_VIDEOS_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_VIDEOS_REJECTED, error });
  }
}

export function* fetchVideo(action) {
  try {
    const id = action.payload;
    const payload = yield call(api.find, 'videos', id);
    yield put({ type: definitions.FETCH_VIDEO_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_VIDEO_REJECTED, error });
  }
}

export function* createVideo(action) {
  try {
    const payload = yield call(api.create, 'videos', { video: action.payload });
    // yield call(api.clearSiteCache);
    yield put({ type: definitions.CREATE_VIDEO_FULFILLED, payload });
    yield put(push('/videos'));
  } catch (error) {
    yield put({ type: definitions.CREATE_VIDEO_REJECTED, error });
  }
}

export function* updateVideo(action) {
  try {
    const { id } = action.payload;
    const payload = yield call(api.update, 'videos', id, { video: action.payload });
    // yield call(api.clearSiteCache);
    yield put({ type: definitions.UPDATE_VIDEO_FULFILLED, payload });
    yield put(push('/videos'));
  } catch (error) {
    yield put({ type: definitions.UPDATE_VIDEO_REJECTED, error });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.FETCH_VIDEOS, fetchVideos),
    takeLatest(definitions.CREATE_VIDEO, createVideo),
    takeLatest(definitions.FETCH_VIDEO, fetchVideo),
    takeLatest(definitions.UPDATE_VIDEO, updateVideo),
  ]);
}