import { all, call, put, takeLatest, takeEvery } from "redux-saga/effects";
import * as api from "api";
import * as definitions from "store/definitions/image";

export function* fetchImages(action) {
  try {
    const filter = action.payload;
    const payload = yield call(api.fetch, "images", filter);
    yield put({ type: definitions.FETCH_IMAGES_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_IMAGES_REJECTED, error });
  }
}

export function* fetchImage(action) {
  try {
    const id = action.payload;
    const payload = yield call(api.find, "images", id);
    yield put({ type: definitions.FETCH_IMAGE_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_IMAGE_REJECTED, error });
  }
}

export function* createImage(action) {
  try {
    const payload = yield call(api.create, "images", action.payload);
    // yield call(api.clearSiteCache);
    yield put({ type: definitions.CREATE_IMAGE_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.CREATE_IMAGE_REJECTED, error });
  }
}

export function* updateImage(action) {
  try {
    const { id } = action.payload;
    const payload = yield call(api.update, "images", id, action.payload);
    // yield call(api.clearSiteCache);
    yield put({ type: definitions.UPDATE_IMAGE_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.UPDATE_IMAGE_REJECTED, error });
  }
}

export function* removeImage(action) {
  try {
    const id = action.payload;
    yield call(api.remove, "images", id);
    // yield call(api.clearSiteCache);
    yield put({ type: definitions.FETCH_IMAGES });
  } catch (error) {
    yield put({ type: definitions.REMOVE_IMAGE_REJECTED, error });
  }
}

export function* openImageDialog(action) {
  try {
    const id = action.payload;
    const payload = yield call(api.find, "images", id);
    yield put({ type: definitions.OPEN_IMAGE_DIALOG_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.OPEN_IMAGE_DIALOG_REJECTED, error });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.FETCH_IMAGES, fetchImages),
    takeEvery(definitions.FETCH_IMAGE, fetchImage),
    takeEvery(definitions.CREATE_IMAGE, createImage),
    takeLatest(definitions.UPDATE_IMAGE, updateImage),
    takeLatest(definitions.REMOVE_IMAGE, removeImage),
    takeLatest(definitions.OPEN_IMAGE_DIALOG, openImageDialog),
  ]);
}
