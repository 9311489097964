import * as definitions from 'store/definitions/event';

export function fetchEvents(filter) {
  return {
    type: definitions.FETCH_EVENTS,
    payload: filter,
  };
}

export function fetchEvent(id) {
  return {
    type: definitions.FETCH_EVENT,
    payload: id,
  };
}

export function createEvent(data, image) {
  return {
    type: definitions.CREATE_EVENT,
    payload: { data, image },
  };
}

export function updateEvent(data, image) {
  return {
    type: definitions.UPDATE_EVENT,
    payload: { data, image },
  };
}
