import React from 'react';
import { Route, Switch } from 'react-router';
import Layout from 'components/Layout';
import List from './List';

const Post = () => (
  <Layout>
    <Switch>
      <Route exact path="/comments" component={List} />
    </Switch>
  </Layout>
);

export default Post;
