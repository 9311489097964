export const FETCH_CLIENTS = 'FETCH_CLIENTS';
export const FETCH_CLIENTS_FULFILLED = 'FETCH_CLIENTS_FULFILLED';
export const FETCH_CLIENTS_REJECTED = 'FETCH_CLIENTS_REJECTED';

export const FETCH_CLIENT = 'FETCH_CLIENT';
export const FETCH_CLIENT_FULFILLED = 'FETCH_CLIENT_FULFILLED';
export const FETCH_CLIENT_REJECTED = 'FETCH_CLIENT_REJECTED';

export const CREATE_CLIENT = 'CREATE_CLIENT';
export const CREATE_CLIENT_FULFILLED = 'CREATE_CLIENT_FULFILLED';
export const CREATE_CLIENT_REJECTED = 'CREATE_CLIENT_REJECTED';

export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_CLIENT_FULFILLED = 'UPDATE_CLIENT_FULFILLED';
export const UPDATE_CLIENT_REJECTED = 'UPDATE_CLIENT_REJECTED';