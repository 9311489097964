export const FETCH_BLOGS = 'FETCH_BLOGS';
export const FETCH_BLOGS_FULFILLED = 'FETCH_BLOGS_FULFILLED';
export const FETCH_BLOGS_REJECTED = 'FETCH_BLOGS_REJECTED';

export const FETCH_BLOG = 'FETCH_BLOG';
export const FETCH_BLOG_FULFILLED = 'FETCH_BLOG_FULFILLED';
export const FETCH_BLOG_REJECTED = 'FETCH_BLOG_REJECTED';

export const CREATE_BLOG = 'CREATE_BLOG';
export const CREATE_BLOG_FULFILLED = 'CREATE_BLOG_FULFILLED';
export const CREATE_BLOG_REJECTED = 'CREATE_BLOG_REJECTED';

export const UPDATE_BLOG = 'UPDATE_BLOG';
export const UPDATE_BLOG_FULFILLED = 'UPDATE_BLOG_FULFILLED';
export const UPDATE_BLOG_REJECTED = 'UPDATE_BLOG_REJECTED';
