import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import {
  TextField,
  Switch,
} from 'formik-material-ui';
import {
  fetchVideo,
  createVideo,
  updateVideo,
} from 'store/actions/video';
import {
  Toolbar,
  Typography,
  Paper,
  Grid,
  Button,
  FormControlLabel,
} from '@material-ui/core';

const VideoForm = (props) => {
  const { fetchVideo, video, match } = props;

  useEffect(() => {
    const { params } = match;
    if (params.id) fetchVideo(params.id);
  }, [fetchVideo, match]);

  const onSubmit = async values => {
    const { params } = props.match;
    if (params.id) {
      props.updateVideo(values);
    } else {
      props.createVideo(values);
    };
  };

  return (
    <Paper>
      <Toolbar>
        <div>
          <Typography variant="h6">
            {props.match.params.id === undefined ? 'Adicionar' : 'Editar'} vídeo
          </Typography>
        </div>
      </Toolbar>
      <Formik
        initialValues={video}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ isValid, isSubmitting }) => (
          <Form autoComplete="off">
            <Grid container spacing={3} style={{ padding: '0 24px 24px 24px' }}>
              <Grid item xs={12}>
                <Field
                  label="Título"
                  name="title"
                  variant="outlined"
                  fullWidth
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="Url do vídeo"
                  name="videoUrl"
                  variant="outlined"
                  fullWidth
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  label="ID do vídeo"
                  name="videoId"
                  variant="outlined"
                  fullWidth
                  disabled
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      label="Ativo"
                      name="isActive"
                      color="primary"
                      component={Switch}
                    />
                  }
                  label="Ativo"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

const mapStateToProps = state => ({
  video: state.video.form,
});

const actions = {
  fetchVideo,
  createVideo,
  updateVideo,
};

export default connect(mapStateToProps, actions)(VideoForm);
