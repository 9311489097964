import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { TextField, Switch } from 'formik-material-ui';
import {
  fetchClient,
  createClient,
  updateClient,
} from 'store/actions/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';

const ClientForm = (props) => {
  const { match } = props;

  const [client] = useSelector(state => [state.client.form]);

  const on = useDispatch();

  useEffect(() => {
    const { params } = match;
    if (params.id) on(fetchClient(params.id));
  }, [on, match]);

  const onSubmit = async values => {
    const { params } = match;
    if (params.id) {
      on(updateClient(values));
    } else {
      on(createClient(values));
    };
  };

  return (
    <Card>
      <CardContent>
        <Formik
          initialValues={client}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ isValid, isSubmitting }) => (
            <Form autoComplete="off">
              <Box display="flex" justifyContent="center">
                <Grid container spacing={3} style={{ maxWidth: 600 }}>
                  <Grid item align="center" xs={12}>
                    <Typography variant="h6">
                      {!match.params.id ? 'Adicionar' : 'Editar'} site
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="Nome"
                      name="name"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="AWS Access Key ID"
                      name="awsAccessKeyId"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="AWS Secret Access Key"
                      name="awsSecretAccessKey"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="AWS Fog Directory"
                      name="awsFogDirectory"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="Code Image Steam App"
                      name="nodeImageSteamApp"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="URL"
                      name="url"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      label="Ativo"
                      control={
                        <Field
                          name="isActive"
                          color="primary"
                          component={Switch}
                        />
                      }
                    />
                  </Grid>
                  <Grid item align="right" xs={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      disabled={!isValid || isSubmitting}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default ClientForm;
