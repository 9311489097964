import * as definitions from 'store/definitions/newspaper';

export function fetchNewspapers(filter) {
  return {
    type: definitions.FETCH_NEWSPAPERS,
    payload: filter,
  };
}

export function fetchNewspaper(id) {
  return {
    type: definitions.FETCH_NEWSPAPER,
    payload: id,
  };
}

export function createNewspaper(data, image) {
  return {
    type: definitions.CREATE_NEWSPAPER,
    payload: { data, image },
  };
}

export function updateNewspaper(data, image) {
  return {
    type: definitions.UPDATE_NEWSPAPER,
    payload: { data, image },
  };
}
