import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, IconButton } from '@material-ui/core';
import { thumbUrl } from "helpers/thumb";
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 150,
    height: 150,
  },
  iconButton: {
    padding: 10,
  },
}));

const Image = ({ data, file, setFile, setFieldValue }) => {
  const classes = useStyles();

  const [ image, setImage ] = React.useState(null);

  const handleSelectFile = (e) => {
    if (e.target.files.length === 0) return false;
    const files = Array.from(e.target.files);
    const reader = new FileReader();
    reader.onload = e => setImage(e.target.result);
    reader.readAsDataURL(files[0]);
    const formData = new FormData();
    formData.append('image', files[0]);
    setFile(formData);
    setFieldValue('image.src', '');
  };

  const getAvatarSrc = () => {
    if (file) return image;
    if (!data.image) return '';
    return thumbUrl(
      data.image.app,
      data.image.path,
      150,
      150
    );
  };

  return (
    <>
      <Avatar
        variant="circle"
        src={getAvatarSrc()}
        className={classes.avatar}
        style={{ marginTop: 24, marginBottom: 8 }}
      />
      <IconButton
        component="label"
        className={classes.iconButton}
      >
        <EditIcon />
        <input
          type="file"
          onChange={handleSelectFile}
          style={{ display: "none" }}
        />
      </IconButton>
    </>
  );
};

export default Image;
