import React from 'react';
import { connect } from 'react-redux';
import { thumbUrl } from 'helpers/thumb';
import {
  Dialog,
  Grid,
} from '@material-ui/core';
import {
  DialogTitle,
  DialogContent,
} from 'utils/CustomDialog';
import ImagesOptions from 'components/Image/Options';

const ImageInTextDialog = (props) => {
  const { open, handleClose } = props;

  const handleInsert = (url) => {
    props.editor.model.change(writer => {
      const imageElement = writer.createElement('image', {
        src: url,
      });
      props.editor.model.insertContent(imageElement, props.editor.model.document.selection);
    });
    handleClose(false);
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        Selecionar imagem
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <ImagesOptions />
          </Grid>
          {props.images.map((image, index) => (
            <Grid item md={3} key={index}>
              <div style={{ position: 'relative' }}>
                <img
                  src={thumbUrl(image.src.app, image.src.path, 600, 400)}
                  alt={image.description}
                  onClick={() => handleInsert(image.src.url)}
                  style={{ maxWidth: '100%' }}
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  images: state.image.collection,
});

export default connect(mapStateToProps)(ImageInTextDialog);
