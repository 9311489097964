import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetch } from "api";
import { fetchPosts } from "store/actions/post";
import { Button, Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AccountContext from "contexts/account";

const PostSearch = (props) => {
  const account = React.useContext(AccountContext);
  const [categories, setCategories] = React.useState([]);

  const [filter, setFilter] = useState({
    title: "",
    categoryId: "",
    startDate: null,
    endDate: null,
    mostVisited: false,
  });

  useEffect(() => {
    fetch("categories", { isActive: true }).then((res) => setCategories(res));
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    let filters = {
      title_cont: filter.title,
      category_id_eq: filter.categoryId,
    };
    props.setFilter(filters);
    props.setPage(0);
    props.fetchPosts(filters);
  };

  return (
    <div style={{ padding: 18, backgroundColor: "#f5f5f5" }}>
      <form onSubmit={(e) => handleSearch(e)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Pesquisar"
              value={filter.title}
              onChange={(e) => setFilter({ ...filter, title: e.target.value })}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          {!account.isBlogger && (
            <Grid item xs={12} sm={2}>
              <Autocomplete
                options={categories}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) =>
                  setFilter({ ...filter, categoryId: value.id })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Categoria"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={3}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Button type="submit" variant="outlined" color="primary">
              Pesquisar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: state.category.collection,
});

const actions = {
  fetchPosts,
};

export default connect(mapStateToProps, actions)(PostSearch);
