import React from "react";
import { fetch } from "api";
import { Grid, CssBaseline, CircularProgress } from "@material-ui/core";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import FeaturedText from "./Text";
import FeaturedImage from "./Image";
import makeStyles from "./style";
import Sidebar from "./Sidebar";

const useStyles = makeStyles;

const Featured = () => {
  const classes = useStyles();

  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    function fetchData() {
      fetch("highlights").then((res) => {
        setItems(res.highlights);
        setLoading(false);
      });
    }
    fetchData();
  }, []);

  const getDataByType = (type) => {
    function search(i) {
      return i.kind === type;
    }
    return items.find((i) => search(i));
  };

  if (loading)
    return (
      <div
        style={{
          alignItems: "center",
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          left: 0,
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >
        <CircularProgress />
      </div>
    );

  return (
    <DndProvider backend={Backend}>
      <div className={classes.root}>
        <CssBaseline />
        <Sidebar items={items} setItems={setItems} />
        <main className={classes.content}>
          <div style={{ maxWidth: 1280, margin: "0 auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={1} />
              <Grid item xs={10}>
                <FeaturedText
                  data={getDataByType("main1")}
                  items={items}
                  setItems={setItems}
                />
              </Grid>
              <Grid item xs={6}>
                <FeaturedImage
                  data={getDataByType("main2")}
                  items={items}
                  setItems={setItems}
                  big
                />
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FeaturedImage
                      data={getDataByType("main3")}
                      items={items}
                      setItems={setItems}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FeaturedImage
                      data={getDataByType("main4")}
                      items={items}
                      setItems={setItems}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FeaturedImage
                      data={getDataByType("main5")}
                      items={items}
                      setItems={setItems}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FeaturedImage
                      data={getDataByType("main6")}
                      items={items}
                      setItems={setItems}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <FeaturedImage
                  data={getDataByType("secondary1")}
                  items={items}
                  setItems={setItems}
                />
              </Grid>
              <Grid item xs={3}>
                <FeaturedImage
                  data={getDataByType("secondary2")}
                  items={items}
                  setItems={setItems}
                />
              </Grid>
              <Grid item xs={3}>
                <FeaturedImage
                  data={getDataByType("secondary3")}
                  items={items}
                  setItems={setItems}
                />
              </Grid>
              <Grid item xs={3}>
                <FeaturedImage
                  data={getDataByType("secondary4")}
                  items={items}
                  setItems={setItems}
                />
              </Grid>
            </Grid>
          </div>
        </main>
      </div>
    </DndProvider>
  );
};

export default Featured;
