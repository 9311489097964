import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { fetchPosts, massActionPosts } from "store/actions/post";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import Filter from "./Filter";
import TableToolbar from "utils/TableToolbar";
import Paginator from "utils/Paginator";
import AlarmIcon from "@material-ui/icons/Alarm";
import AddIcon from "@material-ui/icons/Add";
import Count from "./Count";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    // minWidth: 650,
    width: "100%",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));

const PostList = (props) => {
  const { fetchPosts, posts, total, search, loading } = props;

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.fetchPosts({ search, ...filter, page: newPage + 1 });
  };

  const classes = useStyles();

  const handleSelect = (id) => {
    if (selected.indexOf(id) === -1) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((i) => i !== id));
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelected(posts.map((i) => i.id));
      return;
    }
    setSelected([]);
  };

  const handleRemove = () => {
    props.massActionPosts(selected);
    setSelected([]);
  };

  return (
    <>
      <Toolbar disableGutters>
        <div className={classes.toolbar}>
          <Typography variant="h6">Notícias</Typography>
          <div>
            <Button
              variant="outlined"
              onClick={() => props.push("/posts/new")}
              style={{ marginLeft: 10 }}
            >
              <AddIcon />
            </Button>
          </div>
        </div>
      </Toolbar>
      <Filter setFilter={setFilter} setPage={setPage} />
      <Paginator
        page={page}
        total={total}
        handleChangePage={handleChangePage}
      />
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < posts.length
                  }
                  checked={
                    selected.length > 0 && selected.length === posts.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell width="1%">Editoria</TableCell>
              <TableCell>Título</TableCell>
              <TableCell>Agendado para</TableCell>
              <TableCell>Clicks</TableCell>
              <TableCell>Posição</TableCell>
              <TableCell width="1%"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ opacity: loading ? 0.3 : 1 }}>
            {posts.map((post) => (
              <TableRow
                key={post.id}
                style={{
                  cursor: "pointer",
                  backgroundColor: moment(post.scheduledTo).isAfter(moment())
                    ? "#f5f5f5"
                    : "#ffffff",
                }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selected.includes(post.id)}
                    onClick={() => handleSelect(post.id)}
                  />
                </TableCell>
                <TableCell>
                  <Chip size="small" label={post.category.name} />
                </TableCell>
                <TableCell onClick={() => props.push(`/posts/${post.id}`)}>
                  {_.truncate(post.title, { length: 80 })}
                </TableCell>
                <TableCell>
                  <nobr style={{ display: "flex" }}>
                    {moment(post.scheduledTo).format("DD/MM/YYYY HH:mm")}
                    {moment(post.scheduledTo).isAfter(moment()) && (
                      <AlarmIcon fontSize="small" style={{ marginLeft: 8 }} />
                    )}
                  </nobr>
                </TableCell>
                <TableCell>
                  <Count id={post.id} />
                </TableCell>
                <TableCell>
                  <div style={{ whiteSpace: "nowrap" }}>
                    {post.position === "rm_1" && "Posição 1"}
                    {post.position === "rm_2" && "Posição 2"}
                    {post.position === "rm_3" && "Posição 3"}
                  </div>
                </TableCell>
                <TableCell>
                  <div style={{ display: "flex", gap: "8px" }}>
                    <Chip
                      size="small"
                      color="default"
                      label={post.clientName}
                    />
                    <Chip
                      size="small"
                      color={post.isActive ? "primary" : "secondary"}
                      label={post.isActive ? "Ativo" : "Inativo"}
                    />
                    <Chip
                      size="small"
                      color={post.featured ? "primary" : "default"}
                      label="Destaque"
                    />
                    <Chip
                      size="small"
                      color={post.sponsored ? "primary" : "default"}
                      label="Patrocinado"
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TableToolbar
          title="Notícias"
          selected={selected}
          handleRemove={handleRemove}
        />
      </Paper>
      <Paginator
        page={page}
        total={total}
        handleChangePage={handleChangePage}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  posts: state.post.collection,
  total: state.post.total,
  search: state.post.search,
  loading: state.post.loading,
});

const actions = {
  fetchPosts,
  massActionPosts,
  push,
};

export default connect(mapStateToProps, actions)(PostList);
