export const FETCH_POSTS = 'FETCH_POSTS';
export const FETCH_POSTS_FULFILLED = 'FETCH_POSTS_FULFILLED';
export const FETCH_POSTS_REJECTED = 'FETCH_POSTS_REJECTED';

export const FETCH_POST = 'FETCH_POST';
export const FETCH_POST_FULFILLED = 'FETCH_POST_FULFILLED';
export const FETCH_POST_REJECTED = 'FETCH_POST_REJECTED';

export const CREATE_POST = 'CREATE_POST';
export const CREATE_POST_FULFILLED = 'CREATE_POST_FULFILLED';
export const CREATE_POST_REJECTED = 'CREATE_POST_REJECTED';

export const UPDATE_POST = 'UPDATE_POST';
export const UPDATE_POST_FULFILLED = 'UPDATE_POST_FULFILLED';
export const UPDATE_POST_REJECTED = 'UPDATE_POST_REJECTED';

export const MASS_ACTION_POSTS = 'MASS_ACTION_POSTS';
export const MASS_ACTION_POSTS_FULFILLED = 'MASS_ACTION_POSTS_FULFILLED';
export const MASS_ACTION_POSTS_REJECTED = 'MASS_ACTION_POSTS_REJECTED';
