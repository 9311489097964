import * as definitions from 'store/definitions/video';

export function fetchVideos(filter) {
  return {
    type: definitions.FETCH_VIDEOS,
    payload: filter,
  };
}

export function fetchVideo(id) {
  return {
    type: definitions.FETCH_VIDEO,
    payload: id,
  };
}

export function createVideo(data) {
  return {
    type: definitions.CREATE_VIDEO,
    payload: data,
  };
}

export function updateVideo(data) {
  return {
    type: definitions.UPDATE_VIDEO,
    payload: data,
  };
}