export const FETCH_MOVIES = 'FETCH_MOVIES';
export const FETCH_MOVIES_FULFILLED = 'FETCH_MOVIES_FULFILLED';
export const FETCH_MOVIES_REJECTED = 'FETCH_MOVIES_REJECTED';

export const FETCH_MOVIE = 'FETCH_MOVIE';
export const FETCH_MOVIE_FULFILLED = 'FETCH_MOVIE_FULFILLED';
export const FETCH_MOVIE_REJECTED = 'FETCH_MOVIE_REJECTED';

export const CREATE_MOVIE = 'CREATE_MOVIE';
export const CREATE_MOVIE_FULFILLED = 'CREATE_MOVIE_FULFILLED';
export const CREATE_MOVIE_REJECTED = 'CREATE_MOVIE_REJECTED';

export const UPDATE_MOVIE = 'UPDATE_MOVIE';
export const UPDATE_MOVIE_FULFILLED = 'UPDATE_MOVIE_FULFILLED';
export const UPDATE_MOVIE_REJECTED = 'UPDATE_MOVIE_REJECTED';
