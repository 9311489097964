import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import BlogReducer from './blog';
import CategoryReducer from './category';
import ClientReducer from './client';
import ConfigReducer from './config';
import EventReducer from './event';
import ImageReducer from './image';
import MovieReducer from './movie';
import NewspaperReducer from './newspaper';
import PostReducer from './post';
import SessionReducer from './session';
import UserReducer from './user';
import VideoReducer from './video';

const rootReducer = history => combineReducers({
  blog: BlogReducer,
  category: CategoryReducer,
  client: ClientReducer,
  config: ConfigReducer,
  event: EventReducer,
  image: ImageReducer,
  movie: MovieReducer,
  newspaper: NewspaperReducer,
  post: PostReducer,
  session: SessionReducer,
  user: UserReducer,
  video: VideoReducer,
  router: connectRouter(history),
});

export default rootReducer;
