import React from "react";
import * as api from "api/session";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Link,
  Typography,
} from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import { useHistory } from "react-router-dom";
import AccountContext from "contexts/account";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

function MadeWithLove() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="http://www.cadaminuto.com.br">
        Cadaminuto
      </Link>
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignIn = (props) => {
  const classes = useStyles();
  const account = React.useContext(AccountContext);
  const history = useHistory();

  const onSubmit = async (values, { setSubmitting }) => {
    const { email, password } = values;
    await api.login(email, password).then(
      (res) => {
        localStorage.setItem("access-token", res.data.token);
        account.setData(res.data.account);
        history.push("/");
      },
      (error) => {
        if (error.response.status === 400) {
          alert("Usuário ou senha incorretos.");
        } else {
          alert(
            "Houve um erro ao acessar sua conta, por favor entre em contato com o suporte."
          );
        }
        setSubmitting(false);
      }
    );
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ isValid }) => (
            <Form autoComplete="off" className={classes.form}>
              <Field
                label="E-mail"
                name="email"
                variant="outlined"
                margin="normal"
                autoComplete="email"
                autoFocus
                fullWidth
                component={TextField}
              />
              <Field
                label="Senha"
                name="password"
                variant="outlined"
                margin="normal"
                type="password"
                autoComplete="current-password"
                fullWidth
                component={TextField}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Lembrar-me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!isValid}
              >
                Logar
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <Box mt={5}>
        <MadeWithLove />
      </Box>
    </Container>
  );
};

export default SignIn;
