import { push } from 'connected-react-router';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as api from 'api';
import * as definitions from 'store/definitions/newspaper';

export function* fetchNewspapers(action) {
  try {
    const filter = action.payload;
    const payload = yield call(api.fetch, 'newspapers', filter);
    yield put({ type: definitions.FETCH_NEWSPAPERS_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_NEWSPAPERS_REJECTED, error });
  }
}

export function* fetchNewspaper(action) {
  try {
    const id = action.payload;
    const payload = yield call(api.find, 'newspapers', id);
    yield put({ type: definitions.FETCH_NEWSPAPER_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_NEWSPAPER_REJECTED, error });
  }
}

export function* createNewspaper(action) {
  try {
    const { data, image } = action.payload;
    const payload = yield call(api.create, 'newspapers', { newspaper: data });
    yield call(api.upload, 'newspapers', payload.id, image);
    yield put({ type: definitions.CREATE_NEWSPAPER_FULFILLED, payload });
    yield put(push('/newspapers'));
  } catch (error) {
    yield put({ type: definitions.CREATE_NEWSPAPER_REJECTED, error });
  }
}

export function* updateNewspaper(action) {
  try {
    const { data, image } = action.payload;
    const { id } = data;
    const payload = yield call(api.update, 'newspapers', id, { newspaper: data });
    if (image) yield call(api.upload, 'newspapers', id, image);
    yield put({ type: definitions.UPDATE_NEWSPAPER_FULFILLED, payload });
    yield put(push('/newspapers'));
  } catch (error) {
    yield put({ type: definitions.UPDATE_NEWSPAPER_REJECTED, error });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.FETCH_NEWSPAPERS, fetchNewspapers),
    takeLatest(definitions.CREATE_NEWSPAPER, createNewspaper),
    takeLatest(definitions.FETCH_NEWSPAPER, fetchNewspaper),
    takeLatest(definitions.UPDATE_NEWSPAPER, updateNewspaper),
  ]);
}