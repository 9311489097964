import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyCFU3Q2vrA1AG29Po3WGdAb_qdBp96_Z1c',
  authDomain: 'cadaminuto-comments.firebaseapp.com',
  projectId: 'cadaminuto-comments',
  storageBucket: 'cadaminuto-comments.appspot.com',
  messagingSenderId: '559450761472',
  appId: '1:559450761472:web:bf4d473a37c5523635abe5',
  measurementId: 'G-XWVFVJMM2P'
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default firebase
