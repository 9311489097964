import React from "react";
import { connect } from "react-redux";
import {
  fetchImages,
  updateImage,
  openImageDialog,
  closeImageDialog,
} from "store/actions/image";
import Layout from "components/Layout";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  Grid,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { DialogTitle, DialogContent } from "utils/CustomDialog";
import "./styles.css";

const ImageList = (props) => {
  const {
    image,
    images,
    fetchImages,
    updateImage,
    open,
    openImageDialog,
    closeImageDialog,
    totalPages,
  } = props;

  React.useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  const onSubmit = async (values) => {
    await updateImage(values);
    fetchImages();
  };

  return (
    <Layout>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Imagem</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Crédito</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {images.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <img
                    src={row.src.url}
                    alt={row.description}
                    width={200}
                    height={130}
                  />
                </TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.credit}</TableCell>
                <TableCell>
                  <Button onClick={() => openImageDialog(row.id)}>
                    Editar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ padding: "20px 0" }}>
        <Pagination
          count={totalPages}
          onChange={(_, page) => {
            fetchImages({ page });
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />
      </div>
      <Dialog
        onClose={() => closeImageDialog()}
        open={open}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle onClose={() => closeImageDialog()}>
          Editar imagem
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Formik
                initialValues={image}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({ isValid, isSubmitting }) => (
                  <Form autoComplete="off">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Field
                          multiline
                          label="Descrição"
                          name="description"
                          variant="outlined"
                          fullWidth
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          label="Crédito"
                          name="credit"
                          variant="outlined"
                          fullWidth
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          label="Tags"
                          name="tags"
                          variant="outlined"
                          fullWidth
                          component={TextField}
                        />
                      </Grid>
                      <Grid item md={12} align="right">
                        <Button
                          variant="outlined"
                          color="primary"
                          type="submit"
                          disabled={!isValid || isSubmitting}
                        >
                          Salvar
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  open: state.image.openDialog,
  image: state.image.form,
  images: state.image.collection,
  totalPages: state.image.totalPages,
});

const actions = {
  updateImage,
  fetchImages,
  openImageDialog,
  closeImageDialog,
};

export default connect(mapStateToProps, actions)(ImageList);
