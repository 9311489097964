import * as definitions from 'store/definitions/movie';

export function fetchMovies(filter) {
  return {
    type: definitions.FETCH_MOVIES,
    payload: filter,
  };
}

export function fetchMovie(id) {
  return {
    type: definitions.FETCH_MOVIE,
    payload: id,
  };
}

export function createMovie(data, image) {
  return {
    type: definitions.CREATE_MOVIE,
    payload: { data, image },
  };
}

export function updateMovie(data, image) {
  return {
    type: definitions.UPDATE_MOVIE,
    payload: { data, image },
  };
}
