import React, { useState } from "react";
import { connect } from "react-redux";
import { fetchUsers } from "store/actions/user";
import { Button, Grid, TextField } from "@material-ui/core";

const Filter = (props) => {
  const [filter, setFilter] = useState({
    name: "",
  });

  const handleSearch = (e) => {
    e.preventDefault();
    let filters = {
      name: filter.name,
    };
    props.setFilter(filters);
    props.setPage(0);
    props.fetchUsers(filters);
  };

  return (
    <div style={{ padding: 18 }}>
      <form onSubmit={(e) => handleSearch(e)}>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <TextField
              placeholder="Pesquisar pelo nome"
              value={filter.name}
              onChange={(e) => setFilter({ ...filter, name: e.target.value })}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
            <Button type="submit" variant="outlined" color="primary">
              Buscar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const actions = {
  fetchUsers,
};

export default connect(null, actions)(Filter);
