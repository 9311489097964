import * as definitions from 'store/definitions/image';

export function fetchImages(filter) {
  return {
    type: definitions.FETCH_IMAGES,
    payload: filter,
  };
}

export function fetchImage(id) {
  return {
    type: definitions.FETCH_IMAGE,
    payload: id,
  };
}

export function createImage(data) {
  return {
    type: definitions.CREATE_IMAGE,
    payload: data,
  };
}

export function createImages(data) {
  return {
    type: definitions.CREATE_IMAGES,
    payload: data,
  };
}

export function updateImage(data) {
  return {
    type: definitions.UPDATE_IMAGE,
    payload: data,
  };
}

export function removeImage(id) {
  return {
    type: definitions.REMOVE_IMAGE,
    payload: id,
  };
}

export function openImageDialog(id) {
  return {
    type: definitions.OPEN_IMAGE_DIALOG,
    payload: id,
  };
}

export function closeImageDialog() {
  return {
    type: definitions.CLOSE_IMAGE_DIALOG,
  };
}
