export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_FULFILLED = 'FETCH_CATEGORIES_FULFILLED';
export const FETCH_CATEGORIES_REJECTED = 'FETCH_CATEGORYS_REJECTED';

export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const FETCH_CATEGORY_FULFILLED = 'FETCH_CATEGORY_FULFILLED';
export const FETCH_CATEGORY_REJECTED = 'FETCH_CATEGORY_REJECTED';

export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_FULFILLED = 'CREATE_CATEGORY_FULFILLED';
export const CREATE_CATEGORY_REJECTED = 'CREATE_CATEGORY_REJECTED';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_FULFILLED = 'UPDATE_CATEGORY_FULFILLED';
export const UPDATE_CATEGORY_REJECTED = 'UPDATE_CATEGORY_REJECTED';