import { push } from 'connected-react-router';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as api from 'api';
import * as definitions from 'store/definitions/client';

export function* fetchClients(action) {
  try {
    const filter = action.payload;
    const payload = yield call(api.fetch, 'clients', filter);
    yield put({ type: definitions.FETCH_CLIENTS_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_CLIENTS_REJECTED, error });
  }
}

export function* fetchClient(action) {
  try {
    const id = action.payload;
    const payload = yield call(api.find, 'clients', id);
    yield put({ type: definitions.FETCH_CLIENT_FULFILLED, payload });
  } catch (error) {
    yield put({ type: definitions.FETCH_CLIENT_REJECTED, error });
  }
}

export function* createClient(action) {
  try {
    const payload = yield call(api.create, 'clients', { client: action.payload });
    // yield call(api.clearSiteCache);
    yield put({ type: definitions.CREATE_CLIENT_FULFILLED, payload });
    yield put(push('/clients'));
  } catch (error) {
    yield put({ type: definitions.CREATE_CLIENT_REJECTED, error });
  }
}

export function* updateClient(action) {
  try {
    const { id } = action.payload;
    const payload = yield call(api.update, 'clients', id, { client: action.payload });
    // yield call(api.clearSiteCache);
    yield put({ type: definitions.UPDATE_CLIENT_FULFILLED, payload });
    yield put(push('/clients'));
  } catch (error) {
    yield put({ type: definitions.UPDATE_CLIENT_REJECTED, error });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.FETCH_CLIENTS, fetchClients),
    takeLatest(definitions.FETCH_CLIENT, fetchClient),
    takeLatest(definitions.CREATE_CLIENT, createClient),
    takeLatest(definitions.UPDATE_CLIENT, updateClient),
  ]);
}