import React from 'react';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

const TableToolbar = props => {
  const classes = useToolbarStyles();

  const { selected } = props;

  const handleRemove = () => {
    if (window.confirm('Tem certeza que deseja remover as notícias selecionadas?'))
      return props.handleRemove();
    return;
  }

  return (
    <>
      {selected.length > 0 && (
        <Toolbar
          className={clsx(classes.root, {
            [classes.highlight]: selected.length > 0,
          })}
        >
          <div className={classes.title}>
            <Typography color="inherit" variant="subtitle1">
              {selected.length} selecionado{selected.length > 1 ? 's' : ''}
            </Typography>
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            <Tooltip title="Remover">
              <IconButton onClick={() => handleRemove()} >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      )}
    </>
  );
};

export default TableToolbar;