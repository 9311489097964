import React from "react";
import { useHistory } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import {
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import Layout from "components/Layout";
import axios from "axios";
import AccountContext from "contexts/account";

const initialValues = {
  name: "",
  email: "",
  password: "",
  passwordConfirmation: "",
};

const validate = (values) => {
  const errors = {};

  if (!values.name) errors.name = "Obrigatório";

  if (!values.email) {
    errors.email = "Obrigatório";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Endereço de e-mail inválido";
  }

  if (values.passwordConfirmation && !values.password) {
    errors.password = "Obrigatório";
  }

  if (values.password && !values.passwordConfirmation) {
    errors.passwordConfirmation = "Obrigatório";
  }

  if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = "As senhas não coincidem";
  }

  return errors;
};

export default function Account() {
  const history = useHistory();
  const account = React.useContext(AccountContext);

  const [user, setUser] = React.useState(initialValues);

  React.useEffect(() => {
    if (account.ready) {
      setUser({
        name: account.data.name,
        email: account.data.email,
        password: "",
        passwordConfirmation: "",
      });
    }
  }, [account]);

  const onSubmit = async (values, { setSubmitting }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    axios
      .patch(`${apiUrl}/account/update`, { user: values })
      .then(
        () => {
          setSubmitting(false);
          alert("Conta atualizada com sucesso");
        },
        () => {
          setSubmitting(false);
          alert("Houve um problema ao atualizar sua conta")
        }
      );
  };

  return (
    <Layout>
      <div>
        <Formik
          initialValues={user}
          onSubmit={onSubmit}
          validate={validate}
          enableReinitialize
        >
          {({ isValid, isSubmitting }) => (
            <Form autoComplete="off">
              <Grid container spacing={3} style={{ maxWidth: 600 }}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Editar conta
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="Nome"
                    name="name"
                    variant="outlined"
                    fullWidth
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="E-mail"
                    name="email"
                    variant="outlined"
                    fullWidth
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="Senha"
                    name="password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="Confirmação de senha"
                    name="passwordConfirmation"
                    type="password"
                    variant="outlined"
                    fullWidth
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => history.push("/")}
                    style={{ marginRight: 24 }}
                  >
                    Voltar
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
