import React, { useState, useEffect, useCallback } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Paper,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import firebase from '../../firebase/clientApp'
import AccountContext from 'contexts/account'

const db = firebase.firestore()

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    minWidth: 650,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));

const CommentList = () => {
  const classes = useStyles()

  const [comments, setComments] = useState()

  const account = React.useContext(AccountContext)

  const fetch = useCallback(async () => {
    if (account.isBlogger) {
      const response = db.collection('comments')
        .where('approved', '==', false)
        .where('active', '==', true)
        .where('categoryId', '==', account.data.categoryId)
      const data = await response.get()
      setComments(data)
    } else {
      const response = db.collection('comments')
        .where('approved', '==', false)
        .where('active', '==', true)
      const data = await response.get()
      setComments(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.data?.categoryId, account.isAdmin])

  useEffect(() => {
    if (!account.ready) return
    fetch()
  }, [account.ready, fetch])

  const handleApprove = useCallback(async id => {
    await db.collection('comments')
      .doc(id)
      .update({ approved: true })
      .then(() => fetch())
  }, [fetch])

  const handleDelete = useCallback(async id => {
    await db.collection('comments')
      .doc(id)
      .update({ active: false })
      .then(() => fetch())
  }, [fetch])

  return (
    <>
      <Toolbar disableGutters>
        <div className={classes.toolbar}>
          <Typography variant="h6">Moderar comentários</Typography>
        </div>
      </Toolbar>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Comentário</TableCell>
              <TableCell></TableCell>
              <TableCell width="1%"></TableCell>
              <TableCell width="1%"></TableCell>
            </TableRow>
          </TableHead>
          {comments && (
            <TableBody>
              {comments.docs.map((comment) => (
                <TableRow
                  key={comment.id}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell>
                    {comment.data().name}
                  </TableCell>
                  <TableCell>
                    {comment.data().comment}
                  </TableCell>
                  <TableCell>
                    <a
                      href={`/posts/${comment.data().postId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      Link da notícia
                    </a>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleApprove(comment.id)}
                      variant="outlined"
                      color="primary"
                    >
                      Aprovar
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleDelete(comment.id)}
                      color="secondary"
                    >
                      Excluir
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </Paper>
    </>
  );
};

export default CommentList;
