export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_EVENTS_FULFILLED = 'FETCH_EVENTS_FULFILLED';
export const FETCH_EVENTS_REJECTED = 'FETCH_EVENTS_REJECTED';

export const FETCH_EVENT = 'FETCH_EVENT';
export const FETCH_EVENT_FULFILLED = 'FETCH_EVENT_FULFILLED';
export const FETCH_EVENT_REJECTED = 'FETCH_EVENT_REJECTED';

export const CREATE_EVENT = 'CREATE_EVENT';
export const CREATE_EVENT_FULFILLED = 'CREATE_EVENT_FULFILLED';
export const CREATE_EVENT_REJECTED = 'CREATE_EVENT_REJECTED';

export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_EVENT_FULFILLED = 'UPDATE_EVENT_FULFILLED';
export const UPDATE_EVENT_REJECTED = 'UPDATE_EVENT_REJECTED';
