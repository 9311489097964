import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Field, Form } from "formik";
import { TextField, Switch } from "formik-material-ui";
import {
  fetchCategory,
  createCategory,
  updateCategory,
} from "store/actions/category";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Typography,
  MenuItem,
} from "@material-ui/core";
import Image from "./Image";

const CategoryForm = (props) => {
  const { match } = props;

  const [file, setFile] = React.useState(null);

  const [category] = useSelector((state) => [state.category.form]);

  const on = useDispatch();

  useEffect(() => {
    const { params } = match;
    if (params.id) on(fetchCategory(params.id));
  }, [on, match]);

  const onSubmit = async (values) => {
    const { params } = match;

    const payload = { ...values };

    if (payload.isArchived) {
      payload.isActive = false;
    }

    if (params.id) {
      on(updateCategory(payload, file));
    } else {
      on(createCategory(payload, file));
    }
  };

  return (
    <Card>
      <CardContent>
        <Formik initialValues={category} onSubmit={onSubmit} enableReinitialize>
          {({ isValid, isSubmitting, values, setFieldValue }) => (
            <Form autoComplete="off">
              <Box display="flex" justifyContent="center">
                <Grid container spacing={3} style={{ maxWidth: 600 }}>
                  <Grid item align="center" xs={12}>
                    <Typography variant="h6">
                      {!match.params.id ? "Adicionar" : "Editar"} categoria
                    </Typography>
                    <Image
                      data={category}
                      file={file}
                      setFile={setFile}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="Nome"
                      name="name"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="Descrição"
                      name="description"
                      rows="4"
                      variant="outlined"
                      multiline
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      label="Ordem"
                      name="order"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  {values.isBlog && (
                    <Grid item xs={12}>
                      <Field
                        select
                        label="Tipo"
                        name="kind"
                        variant="outlined"
                        fullWidth
                        component={TextField}
                      >
                        <MenuItem value="especialista">Especialista</MenuItem>
                        <MenuItem value="colunista">Colunista</MenuItem>
                        <MenuItem value="municipio">Município</MenuItem>
                        <MenuItem value="padrao">Padrão</MenuItem>
                        <MenuItem value="pensata">Pensata</MenuItem>
                      </Field>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormControlLabel
                      label="Blog"
                      control={
                        <Field
                          name="isBlog"
                          color="primary"
                          component={Switch}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      label="Arquivado"
                      control={
                        <Field
                          name="isArchived"
                          color="secondary"
                          component={Switch}
                        />
                      }
                    />
                  </Grid>
                  {!values.isArchived && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        label="Ativo"
                        control={
                          <Field
                            name="isActive"
                            color="primary"
                            component={Switch}
                          />
                        }
                      />
                    </Grid>
                  )}
                  <Grid item align="right" xs={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      disabled={!isValid || isSubmitting}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default CategoryForm;
