import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import HighlightIcon from "@material-ui/icons/Highlight";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CategoryIcon from "@material-ui/icons/Category";
import DescriptionIcon from "@material-ui/icons/Description";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PeopleIcon from "@material-ui/icons/People";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import ImageIcon from "@material-ui/icons/Image";
import AccountContext from "contexts/account";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: 240,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
}));

export default function Layout({ children }) {
  const classes = useStyles();
  const history = useHistory();
  const account = React.useContext(AccountContext);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.setItem("access-token", "");
    history.push("/login");
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => history.push("/account")}>Minha conta</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => setOpenDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            CM ADMIN
          </Typography>
          <IconButton
            edge="end"
            onClick={handleProfileMenuOpen}
            color="inherit"
            style={{ marginRight: 0 }}
          >
            <AccountCircle />
          </IconButton>
          {renderMenu}
          <IconButton color="inherit" onClick={() => handleLogout()}>
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar style={{ marginBottom: 24 }} />
      <Container maxWidth="xl">{children}</Container>
      <Drawer
        anchor="left"
        open={openDrawer}
        classes={{ paper: classes.drawerPaper }}
        onClose={() => setOpenDrawer(false)}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={() => setOpenDrawer(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List
          subheader={<ListSubheader component="div">Módulos</ListSubheader>}
        >
          {(account.isAdmin || account.isEditor) && (
            <ListItem button onClick={() => history.push("/featured")}>
              <ListItemIcon>
                <HighlightIcon />
              </ListItemIcon>
              <ListItemText primary="Destaques" />
            </ListItem>
          )}
          {(account.isAdmin || account.isEditor) && (
            <ListItem button onClick={() => history.push("/categories")}>
              <ListItemIcon>
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText primary="Categorias" />
            </ListItem>
          )}
          <ListItem button onClick={() => history.push("/comments")}>
            <ListItemIcon>
              <CategoryIcon />
            </ListItemIcon>
            <ListItemText primary="Comentários" />
          </ListItem>
          <ListItem button onClick={() => history.push("/posts")}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Notícias" />
          </ListItem>
          {(account.isCadaminuto || account.isMinutoSertao) &&
            (account.isAdmin || account.isEditor) && (
              <ListItem button onClick={() => history.push("/videos")}>
                <ListItemIcon>
                  <VideoLibraryIcon />
                </ListItemIcon>
                <ListItemText primary="Vídeos" />
              </ListItem>
            )}
          {account.isCadaminuto && (account.isAdmin || account.isEditor) && (
            <ListItem button onClick={() => history.push("/newspapers")}>
              <ListItemIcon>
                <MenuBookIcon />
              </ListItemIcon>
              <ListItemText primary="Press" />
            </ListItem>
          )}
          {account.isCadaminuto && (account.isAdmin || account.isEditor) && (
            <ListItem button onClick={() => history.push("/events")}>
              <ListItemIcon>
                <CalendarTodayIcon />
              </ListItemIcon>
              <ListItemText primary="Agenda" />
            </ListItem>
          )}
          {account.isCadaminuto && (account.isAdmin || account.isEditor) && (
            <ListItem button onClick={() => history.push("/lives")}>
              <ListItemIcon>
                <LiveTvIcon />
              </ListItemIcon>
              <ListItemText primary="Lives" />
            </ListItem>
          )}
          <ListItem button onClick={() => history.push("/images")}>
            <ListItemIcon>
              <ImageIcon />
            </ListItemIcon>
            <ListItemText primary="Imagens" />
          </ListItem>
        </List>
        {account.isAdmin && (
          <>
            <Divider />
            <List
              subheader={
                <ListSubheader component="div">Configurações</ListSubheader>
              }
            >
              <ListItem button onClick={() => history.push("/clients")}>
                <ListItemIcon>
                  <LibraryBooksIcon />
                </ListItemIcon>
                <ListItemText primary="Sites" />
              </ListItem>
              <ListItem button onClick={() => history.push("/users")}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Usuários" />
              </ListItem>
            </List>
          </>
        )}
      </Drawer>
    </div>
  );
}
