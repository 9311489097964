import React from "react";
import {
  Button,
  Drawer,
  List,
  Divider,
  ListItem,
  TextField,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { fetch, updateHighlights } from "api";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import HomeIcon from "@material-ui/icons/Home";
import PostItem from "./Post";
import makeStyles from "../style";

const useStyles = makeStyles;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Sidebar = ({ items, setItems }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [posts, setPosts] = React.useState([]);

  React.useEffect(() => {
    function fetchData() {
      fetch("highlights").then((res) => setPosts(res.posts));
    }
    fetchData();
  }, []);

  const on = useDispatch();

  const handleSave = () => {
    updateHighlights({ highlights: [...items] }).then(() => setOpen(true));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      anchor="left"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <List>
        <ListItem>
          <Button
            variant="outlined"
            size="large"
            fullWidth
            onClick={() => handleSave()}
          >
            Salvar
          </Button>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <IconButton
            onClick={() => on(push("/posts"))}
            style={{ marginRight: 12 }}
          >
            <HomeIcon />
          </IconButton>
          <TextField
            select
            label="Template dos destaques"
            name="template"
            value="template_1"
            variant="outlined"
            fullWidth
          >
            {[1, 2, 3, 4, 5].map((i) => (
              <MenuItem key={i} value={`template_${i}`}>
                Template {i}
              </MenuItem>
            ))}
          </TextField>
        </ListItem>
      </List>
      <Divider />
      <List>
        {posts.map((post) => (
          <PostItem
            data={post}
            key={post.id}
            items={items}
            setItems={setItems}
          />
        ))}
      </List>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} color="success">
          Destaques atualizados com sucesso!
        </Alert>
      </Snackbar>
    </Drawer>
  );
};

export default Sidebar;
