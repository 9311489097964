import { push } from "connected-react-router";
import { put, takeEvery, all, call } from "redux-saga/effects";
import * as definitions from "store/definitions/session";
import * as api from "api/session";

function* login(action) {
  try {
    const { email, password } = action.payload;
    const payload = yield call(api.login, email, password);
    localStorage.setItem("access-token", payload.token);
    yield put({ type: definitions.LOGIN_FULFILLED, payload });
    yield put(push("/posts"));
  } catch (e) {
    yield put({ type: definitions.LOGIN_REJECTED });
  }
}

function* logout() {
  localStorage.clear();
  yield put(push("/login"));
}

export default function* rootSaga() {
  yield all([
    takeEvery(definitions.LOGIN, login),
    takeEvery(definitions.LOGOUT, logout),
  ]);
}
