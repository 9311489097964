import * as definitions from 'store/definitions/blog';
import { LOCATION_CHANGE } from 'connected-react-router';

const formState = {
  id: null,
  name: '',
  description: '',
  image: '',
  kind: '',
  external: '',
  isActive: false,
  categoryId: null,
  userId: null,
  category: {
    id: null,
    name: '',
  },
  user: {
    id: null,
    name: '',
  }
};

const initialState = {
  collection: [],
  total: 0,
  currentPage: 1,
  search: '',
  form: formState,
};

function Blog(state = initialState, action) {
  switch (action.type) {
    case definitions.FETCH_BLOGS_FULFILLED: {
      return {
        ...state,
        collection: action.payload.data,
        total: action.payload.total,
        currentPage: action.payload.currentPage,
        search: action.payload.search,
      };
    }
    case definitions.FETCH_BLOG_FULFILLED: {
      return {
        ...state,
        form: action.payload,
      };
    }
    case LOCATION_CHANGE:
      return {
        ...state,
        form: formState,
      };
    default:
      return state;
  }
};

export default Blog;