import axios from "axios";
import qs from "qs";

const apiUrl = process.env.REACT_APP_API_URL;
const siteUrl = process.env.REACT_APP_SITE_URL;

export function fetch(path, filter) {
  return axios
    .get(`${apiUrl}/${path}?${qs.stringify(filter)}`)
    .then((res) => res.data)
    .catch((error) => console.log(error));
}

export function find(path, id) {
  return axios.get(`${apiUrl}/${path}/${id}`).then((res) => res.data);
}

export function create(path, record) {
  return axios.post(`${apiUrl}/${path}`, record).then((res) => res.data);
}

export function update(path, id, record) {
  return axios.patch(`${apiUrl}/${path}/${id}`, record).then((res) => res.data);
}

export function remove(path, id) {
  return axios.delete(`${apiUrl}/${path}/${id}`).then((res) => res.data);
}

export function upload(path, id, formData) {
  return axios
    .patch(`${apiUrl}/${path}/${id}/upload`, formData)
    .then((res) => res.data);
}

export function massAction(path, ids) {
  return axios
    .post(`${apiUrl}/${path}/mass_action`, { ids: ids })
    .then((res) => res.data);
}

export function clearSiteCache() {
  return axios.get(`${siteUrl}/clear_cache`);
}

export function updateHighlights(items) {
  return axios.patch(`${apiUrl}/highlights`, items);
}

export function cropImage(params) {
  return axios.get(
    "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-cda3174d-874f-4919-81e0-4f4a6a50d5cb/admin/image-crop",
    {
      params,
    }
  );
}

export function insertToken() {
  axios.interceptors.request.use((config) => {
    return Object.assign(config, {
      headers: {
        ...config.headers,
        Authorization: localStorage.getItem("access-token"),
      },
    });
  });
}
