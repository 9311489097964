import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchClients } from 'store/actions/client';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Chip,
  Paper,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import Paginator from 'utils/Paginator';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));

const BlogList = (props) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);

  const [clients, total, search] = useSelector(state => [
    state.client.collection,
    state.client.total,
    state.client.search,
  ])

  const on = useDispatch();

  useEffect(() => {
    on(fetchClients());
  }, [on]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    on(fetchClients({ search, page: newPage + 1 }));
  };

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <div className={classes.toolbar}>
          <Typography variant="h6">
            Sites
          </Typography>
          <Button
            variant="outlined"
            onClick={() => on(push('/clients/new'))}
            style={{ marginLeft: 10 }}
          >
            Adicionar
          </Button>
        </div>
      </Toolbar>
      <List component="nav">
        {clients.map(client => (
          <ListItem
            button
            onClick={() => on(push(`/clients/${client.id}`))}
            key={client.id}
          >
            <ListItemText
              primary={client.name}
              secondary={client.url}
            />
            <ListItemSecondaryAction>
              <Chip
                size="small"
                color={client.isActive ? 'primary' : 'secondary'}
                label={client.isActive ? 'Ativo' : 'Inativo'}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Paginator
        page={page}
        total={total}
        handleChangePage={handleChangePage}
      />
    </Paper>
  );
};

export default BlogList;
